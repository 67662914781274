<template>
	<el-menu
		mode="horizontal"
		:default-active="defaultActive($route)"
		@select="onSelect"
	>
		<template v-for="(item, index) in menuList">
			<el-menu-item :key="index" :index="item.key">
				<span>{{ item.name }}</span>
			</el-menu-item>
		</template>
	</el-menu>
</template>
<script>
import { mapGetters } from 'vuex';
import Permission from '@/mixins/Permission';
import { IMenuType } from '@/utils/login/permission';

export default {
	name: 'LayoutMenu',
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			list: [],
			active: '',
		};
	},
	computed: {
		...mapGetters('USER', {
			USER: 'getUserInfo',
		}),
		...mapGetters('PERMISSION', {
			PERMISSION: 'getPermission',
		}),
		...mapGetters('MENU', {
			MENU: 'getMenu',
			ROOT: 'getRoot',
			LEAF: 'getLeaf',
			menuList: 'getMenuList',
			viewList: 'getViewList',
		}),
		defaultActive() {
			return route => {
				return this.menuList.find(item => route.name.indexOf(item.key) !== -1)
					.key;
			};
		},
	},
	mixins: [Permission],
	mounted() {},
	methods: {
		onSelect(key) {
			const current = this.viewList.find(
				item =>
					item.key.indexOf(key) !== -1 &&
					(item.type == IMenuType.MENU_VIEW || item.type == IMenuType.VIEW),
			);
			console.log('-----onSelect-----\n', current);
			if (current) {
				this.$router.push({
					name: current.key,
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
// ::-webkit-scrollbar {
//   display: none;
// }
.el-menu {
	background-color: transparent;
}

.el-menu-item {
	color: #fff !important;
	background-color: transparent !important;
	border-bottom-color: transparent !important;
	&.is-active {
		color: #fff !important;
		position: relative;

		&::after {
			content: '';
			width: 40px;
			height: 2px;
			background-color: #fff;
			position: absolute;
			bottom: 4px;
			left: 50%;
			transform: translateX(-50%);
			border-radius: 4px;
		}
	}
}
</style>
