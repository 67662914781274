import Vue from 'vue';
import Router from 'vue-router';
import routes from './common';
import store from '@/store';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getTokenInfo } from '@/utils/login/storage';
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};
Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: '/',
	routes: routes,
});

NProgress.configure({ showSpinner: false });

/** @enum {number} */
const IHasRouterEnum = {
	/** 需要登录 */
	IsNotLogin: 0,
	/** 存在路由无需做任何操作 */
	HasRouter: 1,
	/** 存在页面需要添加路由 */
	HasView: 2,
	/** 未找到 */
	IsNotFound: 3,
	/** 跳过权限 */
	IsSkipLogin: 4,
};

/**
 * @description 判断当前路由是否存在
 */
function hasRoute(path) {
	return router.getRoutes().findIndex(item => item.path === path) > -1;
}

/**
 * @description 判断页面路径是否需要鉴权
 * @returns
 */
const isSkipLogin = path =>
	[
		'login',
		'forget-password',
		'login-middle',
		'activate-account',
		'login-qrcode',
		'',
		'error',
	].indexOf(path.split('/')[1]) !== -1;

/**
 * @description 判断Token信息是否存在，
 * @returns 存在：有权限，不存在：无权限
 */
const isLogin = () => getTokenInfo() !== '';

/**
 * @description 根据页面权限动态生成页面路由数据
 */
function setRoutesByViewList() {
	const viewList = store.getters['MENU/getViewList'];
	for (const item of viewList) {
		const files = item.path.split('/');
		const fileName = files[files.length - 1];
		const route = {
			path: `/${item.path}`,
			name: item.key,
			meta: {
				title: item.name,
				type: item.type,
				icon: item.icon,
				key: item.key,
				id: item.id,
				pid: item.pid,
			},
			component: () => import(`@/views/${item.path}/${fileName}.vue`),
		};
		// 如果路由不存在，添加路由
		if (!hasRoute(route.path)) {
			router.addRoute('myapp', route);
		}
	}
}

/**
 * @description 路由是否存在
 * @param to
 * @returns
 */
function checkRoute(to) {
	// 跳过登录
	if (isSkipLogin(to.path)) {
		return IHasRouterEnum.IsSkipLogin;
	}

	// 未登录
	if (!isLogin()) {
		return IHasRouterEnum.IsNotLogin;
	}

	// 有路由
	if (hasRoute(to.path)) {
		return IHasRouterEnum.HasRouter;
	}

	const viewList = store.getters['MENU/getViewList'];
	const current = viewList.find(
		item => item.key === to.name || `/${item.path}` === to.path,
	);
	// 存在页面
	if (current) {
		return IHasRouterEnum.HasView;
	}

	return IHasRouterEnum.IsNotFound;
}

/**
 * @description 全局路由导航守卫
 */
router.beforeEach((to, from, next) => {
	NProgress.start();
	const key = checkRoute(to);
	// debugger;
	switch (key) {
		case IHasRouterEnum.IsNotLogin:
			// 有权限未登录，跳转登录
			next({
				path: '/login',
				query: {
					redirect: encodeURIComponent(to.fullPath),
				},
			});
			break;
		case IHasRouterEnum.HasRouter:
			// 有权限有登录直接跳转
			next();
			break;
		case IHasRouterEnum.HasView:
			// 添加路由
			setRoutesByViewList();
			// 添加完成后重新跳转
			// to.path = to.name
			next(to);
			break;
		case IHasRouterEnum.IsNotFound:
			// 不存在
			next('/error/404');
			break;
		case IHasRouterEnum.IsSkipLogin:
			// 跳过权限
			next();
			break;
		default:
			// 其他
			next('/error/404');
			break;
	}
});

/**
 * @description 全局路由后置守卫
 * */
router.afterEach(() => {
	NProgress.done();
});

export default router;
