import { mapActions, mapGetters } from 'vuex';
import { flatten, filterMenu } from '@/utils/tree-tools';
import Api from '@/apis/public/common';
import { splitPermissionData } from '@/utils/login/permission';
// import { menuDemo } from './menu';
import { forEachTree, removeTreeNode } from '@/utils/tree';
import { isBuild } from '@/utils/env';
export default {
	data() {
		return {
			redirectRoute: '', // 用户初次登录将指向的路由
		};
	},
	computed: {
		...mapGetters('MENU', {
			MENU: 'getMenu',
		}),
		...mapGetters('USER', {
			USER: 'getUserInfo',
		}),
	},
	methods: {
		...mapActions('MENU', [
			'setMenu',
			'setMenuList',
			'setViewList',
			'setButtonList',
			'setApiList',
		]),
		...mapActions('PERMISSION', ['setPermission']),
		filterMenu(permission) {
			return filterMenu(this.MENU, item => {
				return permission.includes(item.name);
			});
		},
		/** 获取用户权限 */
		async permissionUpdate() {
			try {
				// 获取权限信息
				const res = await Api.permissionList();
				if (!res.data.length) {
					this.$message.error('无权限访问');
					return;
				}
				// const { data } = menuDemo;
				const data = ['我的应用', '系统设置', '下载中心']
					.map(name => {
						return res.data.find(item => item.name === name);
					})
					.filter(Boolean);
				if (isBuild) {
					removeTreeNode(data, item => {
						return ['下载中心', '权限管理'].includes(item.name);
					});
				}
				console.log('当前用户权限---');
				// 数据格式化
				forEachTree(data, item => {
					item.pid = item.parent_id;
					item.key = item.front_path;
					item.path = item.front_path.replaceAll('_', '/');
				});
				// 数据分组
				const { menuList, viewList, buttonList, apiList } =
					splitPermissionData(data);

				// 存储过滤菜单
				this.setMenu([...data]);
				this.setMenuList(menuList);
				this.setViewList(viewList);
				this.setButtonList(buttonList);
				this.setApiList(apiList);
				// 转化为数组并获取路由
				const permission = flatten(data, 'children').map(
					item => item.front_path,
				);
				// 存储权限信息
				this.setPermission(permission);
				this.$message.success('登录成功');
			} catch (error) {
				console.log('permissionUpdate', error);
			}
		},
		// TODO 获取当前用户可查看的首个菜单项
		reRoute(routers) {
			if (routers.length > 0) {
				if (routers[0].type === 4) {
					this.redirectRoute = routers[0].name;
				} else {
					this.reRoute(routers[0].children);
				}
			} else {
				this.redirectRoute = 'myapp';
			}
			return this.redirectRoute;
		},
	},
};
