'use strict';
var __assign =
	(this && this.__assign) ||
	function () {
		__assign =
			Object.assign ||
			function (t) {
				for (var s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i];
					for (var p in s)
						if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
				}
				return t;
			};
		return __assign.apply(this, arguments);
	};
var __spreadArray =
	(this && this.__spreadArray) ||
	function (to, from, pack) {
		if (pack || arguments.length === 2)
			for (var i = 0, l = from.length, ar; i < l; i++) {
				if (ar || !(i in from)) {
					if (!ar) ar = Array.prototype.slice.call(from, 0, i);
					ar[i] = from[i];
				}
			}
		return to.concat(ar || Array.prototype.slice.call(from));
	};
Object.defineProperty(exports, '__esModule', { value: true });
exports.forEachTree =
	exports.findTreeNode =
	exports.filterTreeNode =
	exports.removeTreeNode =
	exports.insertTreeNode =
	exports.treeToList =
	exports.createTreeFromList =
		void 0;
/**
 * 数组转树
 * @param list 数组
 * @param options 配置
 * @returns
 */
function createTreeFromList(list, options) {
	if (options === void 0) {
		options = {};
	}
	var nodeMap = new Map();
	var result = [];
	var _a = options.id,
		id = _a === void 0 ? 'id' : _a,
		_b = options.pid,
		pid = _b === void 0 ? 'pid' : _b,
		_c = options.children,
		children = _c === void 0 ? 'children' : _c;
	// 如果数组为空，直接返回
	if (list.length === 0) return [];
	for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
		var node = list_1[_i];
		nodeMap.set(node[id], node);
	}
	for (var _d = 0, list_2 = list; _d < list_2.length; _d++) {
		// eslint-disable-next-line no-redeclare
		var node = list_2[_d];
		var parent_1 = nodeMap.get(node[pid]);
		if (parent_1) {
			if (parent_1[children]) {
				parent_1[children].push(node);
			} else {
				parent_1[children] = [node];
			}
		} else {
			result.push(node);
		}
	}
	return result;
}
exports.createTreeFromList = createTreeFromList;
/**
 * 树转数组
 * @param tree 树
 * @param options 配置
 * @returns
 */
function treeToList(tree, options) {
	if (options === void 0) {
		options = {};
	}
	var _a = options.children,
		children = _a === void 0 ? 'children' : _a;
	var result = __spreadArray([], tree, true);
	for (var i = 0; i < result.length; i++) {
		if (!result[i][children]) continue;
		result.splice.apply(
			result,
			__spreadArray([i + 1, 0], result[i][children], false),
		);
	}
	return result;
}
exports.treeToList = treeToList;
/**
 * 插入子节点
 * @param tree
 * @param node
 * @param options
 */
function insertTreeNode(tree, node, options) {
	if (options === void 0) {
		options = {};
	}
	var _a = options.pid,
		pid = _a === void 0 ? 'pid' : _a;
	if (!node[pid]) {
		// 如果父ID不存在，放在最顶层
		tree.push(node);
	} else {
		// 否则查找对应的父节点
		var pNode = findTreeNode(tree, function (item) {
			return item.id == node[pid];
		});
		if (pNode) {
			if (pNode.children) {
				pNode.children.push(node);
			} else {
				pNode.children = [node];
			}
		}
	}
	return tree;
}
exports.insertTreeNode = insertTreeNode;
/**
 * 移除树节点
 * @param tree
 * @param callback
 * @param options
 * @returns
 */
function removeTreeNode(tree, callback, options) {
	if (options === void 0) {
		options = {};
	}
	var _a = options.children,
		children = _a === void 0 ? 'children' : _a;
	// 如果树没有节点，返回
	if (tree.length === 0) return;
	for (var i = 0; i < tree.length; i++) {
		var item = tree[i];
		// 使用回调判断要移除的节点，不考虑是否有子节点
		if (callback(item)) {
			tree.splice(i, 1);
			break;
		}
		// 如果存在子节点，遍历查询子节点
		if (tree[i][children]) {
			removeTreeNode(tree[i][children], callback, options);
		}
	}
}
exports.removeTreeNode = removeTreeNode;
/**
 * 筛选树节点
 * @param tree
 * @param callback
 * @param options
 * @returns
 */
function filterTreeNode(tree, callback, options) {
	if (options === void 0) {
		options = {};
	}
	var result = __spreadArray([], tree, true);
	var _a = options.children,
		children = _a === void 0 ? 'children' : _a;
	function filter(list) {
		return list
			.map(function (node) {
				return __assign({}, node);
			})
			.filter(function (node) {
				// 筛选子节点
				if (node[children]) {
					node.children = filter(node[children]);
				}
				return callback(node) || (node[children] && node[children].length);
			});
	}
	return filter(result);
}
exports.filterTreeNode = filterTreeNode;
/**
 * 获取树节点
 * @param tree
 * @param callback
 * @param options
 * @returns
 */
function findTreeNode(tree, callback, options) {
	if (options === void 0) {
		options = {};
	}
	var _a = options.children,
		children = _a === void 0 ? 'children' : _a;
	for (var _i = 0, tree_1 = tree; _i < tree_1.length; _i++) {
		var node = tree_1[_i];
		if (callback(node)) return node;
		node[children] && tree.push.apply(tree, node[children]);
	}
	return null;
}
exports.findTreeNode = findTreeNode;
/**
 * 遍历树
 * @param tree 树
 * @param callback
 * @param options
 */
function forEachTree(tree, callback, options) {
	if (options === void 0) {
		options = {};
	}
	var list = __spreadArray([], tree, true);
	var _a = options.children,
		children = _a === void 0 ? 'children' : _a;
	for (var i = 0; i < list.length; i++) {
		callback(list[i]);
		list[i][children] &&
			list.splice.apply(
				list,
				__spreadArray([i + 1, 0], list[i][children], false),
			);
	}
}
exports.forEachTree = forEachTree;
