import cache from '@/utils/cache';
import { cacheOptions } from '@/setting';
import { findTreeNode } from '@/utils/tree';

//  单一状态树
const state = {
	// 所有菜单数据
	menu: [],
	// 菜单权限
	menuList: [],
	// 视图权限
	viewList: [],
	// 按钮权限
	buttonList: [],
	// 接口权限
	apiList: [],
	// 面包屑导航
	breadList: [],
};

// getters 暴露出去的获取 state 的计算属性
const getters = {
	/** 获取第一个有效路由 */
	getFirstRoute: state => {
		// 获取第一个可以直接访问的路由
		const item = findTreeNode(
			[...state.menu[0].children],
			item => item.type == 2 || item.type == 4,
		);
		return {
			path: `/${item.path}`,
			name: item.key,
		};
	},
	/** 获取面包屑列表 */
	getBreadList: state => {
		return state.breadList;
	},
	/** 获取菜单 */
	getMenuList: state => {
		if (state.menuList.length === 0) {
			state.menuList = cache.get(cacheOptions.MENU_LIST, true);
		}
		return state.menuList;
	},
	/** 获取页面列表 */
	getViewList: state => {
		if (state.viewList.length === 0) {
			state.viewList = cache.get(cacheOptions.VIEW_LIST, true);
		}
		return state.viewList;
	},
	/** 获取按钮权限 */
	getButtonList: state => {
		if (state.buttonList.length === 0) {
			state.buttonList = cache.get(cacheOptions.BUTTON_LIST, true);
		}
		return state.buttonList;
	},
	/** 获取接口权限 */
	getApiList: state => {
		if (state.apiList.length === 0) {
			state.apiList = cache.get(cacheOptions.API_LIST, true);
		}
		return state.apiList;
	},
	/** 获取所有菜单 */
	getMenu: state => {
		if (state.menu.length === 0) {
			state.menu = cache.get(cacheOptions.MENU, true);
		}
		return state.menu;
	},
};

// mutations 私有化方法，只能被 actions 调用， 可以直接变更状态
const mutations = {
	setBreadList: (state, data) => {
		cache.set(cacheOptions.BREAD_LIST, [...data]);
		state.breadList = [...data];
	},
	setMenuList: (state, data) => {
		cache.set(cacheOptions.MENU_LIST, [...data]);
		state.menuList = [...data];
	},
	setViewList: (state, data) => {
		cache.set(cacheOptions.VIEW_LIST, [...data]);
		state.viewList = [...data];
	},
	setButtonList: (state, data) => {
		cache.set(cacheOptions.BUTTON_LIST, [...data]);
		state.buttonList = [...data];
	},
	setApiList: (state, data) => {
		cache.set(cacheOptions.API_LIST, [...data]);
		state.apiList = [...data];
	},
	// 设置全量 menu
	setMenu: (state, data) => {
		cache.set(cacheOptions.MENU, [...data]);
		state.menu = [...data];
	},
};

// actions 暴露到外部的方法 不直接变更状态
const actions = {
	setBreadList: ({ commit }, data) => {
		commit('setBreadList', data);
	},
	setMenuList: ({ commit }, data) => {
		commit('setMenuList', data);
	},
	setViewList: ({ commit }, data) => {
		commit('setViewList', data);
	},
	setButtonList: ({ commit }, data) => {
		commit('setButtonList', data);
	},
	setApiList: ({ commit }, data) => {
		commit('setApiList', data);
	},
	setMenu: ({ commit }, data) => {
		commit('setMenu', data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
