<template>
	<div class="com-upload">
		<!-- 自定义上传 -->
		<el-upload
			class="upload"
			ref="upload"
			v-loading="loading"
			v-if="isCustom"
			:action="uploadAction"
			:accept="uploadAccept"
			:multiple="uploadMultiple"
			:show-file-list="false"
			:drag="drag"
			:disabled="disabled"
			:list-type="listType"
			:limit="limit"
			:on-exceed="handleExceed"
			:on-success="handleSuccess"
			:on-error="handleError"
			:before-upload="handleBeforeUpload"
			:http-request="httpRequest"
		>
			<slot>
				<i class="el-icon-plus"></i>
			</slot>
			<slot name="tip">
				<el-row class="color-placeholder" slot="tip">{{ tips }}</el-row>
			</slot>
		</el-upload>
		<!-- 非自定义上传 -->
		<el-upload
			class="upload"
			ref="upload"
			v-if="!isCustom"
			:accept="uploadAccept"
			:multiple="uploadMultiple"
			:show-file-list="false"
			:action="uploadAction"
			:name="name"
			:headers="uploadHeaders"
			:drag="drag"
			:disabled="disabled"
			:list-type="listType"
			:limit="limit"
			:on-exceed="handleExceed"
			:on-success="handleSuccess"
			:on-error="handleError"
			:on-progress="handleProgress"
			:before-upload="handleBeforeUpload"
		>
			<slot>
				<i class="el-icon-plus"></i>
			</slot>
			<slot name="tip">
				<el-row class="color-placeholder" slot="tip">{{ tips }}</el-row>
			</slot>
		</el-upload>
	</div>
</template>

<script>
import { uploadOptions } from '@/setting';
import { getTokenInfo } from '@/utils/login/storage';
import upload from '@/utils/upload/index';
export default {
	name: 'ComUpload',
	props: {
		listType: {
			type: String,
			default: 'picture-card',
		},
		/** 文件类型 image/video/file */
		accept: {
			type: String,
			default: 'image',
		},
		/** 是否自定义上传 eg: default、 cos、oss、obs */
		custom: {
			type: String,
			default: uploadOptions.custom,
		},
		/** 默认上传的 action */
		action: {
			type: String,
			default: '',
		},
		/** 默认上传的 name */
		name: {
			type: String,
			default: uploadOptions.name,
		},
		/** 默认上传的 header */
		headers: {
			type: Object,
			default: () => ({}),
		},
		/** 数量限制 */
		limit: {
			type: Number,
			default: 1,
		},
		/** 禁止上传 */
		disabled: {
			type: Boolean,
			default: false,
		},
		/** 是否可以拖拽上传 */
		drag: {
			type: Boolean,
			default: false,
		},
		/** 大小 */
		size: {
			type: Number,
			default: 0,
		},
		/** 尺寸 - 宽 */
		width: {
			type: Number,
			default: 750,
		},
		/** 尺寸 - 高 */
		height: {
			type: Number,
			default: 750,
		},
		/** 严格校验 */
		strict: {
			type: Boolean,
			default: false,
		},
		/** 自定义上传方法 */
		beforeUpload: {
			type: Function,
		},
	},
	data() {
		return {
			loadingInstance: null, // 非自定义上传loading
			options: {
				fullscreen: true, // loading 配置文件
				background: 'rgba(0,0,0,0.1)',
			},
			loading: false,
		};
	},
	computed: {
		isCustom() {
			return this.custom !== 'default';
		},
		/** 上传地址 */
		uploadAction() {
			if (this.isCustom) {
				return 'upload';
			} else {
				return this.action || uploadOptions.action;
			}
		},
		/** 上传header */
		uploadHeaders() {
			return {
				Authorization: getTokenInfo(),
				...this.headers,
			};
		},
		/** 文件大小限制 */
		uploadSize() {
			return this.size || uploadOptions.size[this.accept];
		},
		/** 是否上传多张 */
		uploadMultiple() {
			return this.limit > 1;
		},
		/** 上传类型 */
		uploadAccept() {
			return uploadOptions.accept[this.accept];
		},
		/** 宽高比 */
		aspectRatio() {
			return this.width / this.height;
		},
		/** 提示信息 */
		tips() {
			const { width, height, uploadSize, accept } = this;
			if (accept === 'video') {
				return `大小不超过${uploadSize}M.`;
			}
			return `建议上传尺寸为${width}*${height}的文件，大小不超过${uploadSize}M.`;
		},
	},
	methods: {
		// 上传之前
		handleBeforeUpload(file) {
			console.log('handleBeforeUpload', file);
			// 上传类型
			const accept = this.uploadAccept.split(',');
			// 文件大小
			const size = this.uploadSize * 1024 * 1024;
			// 自定义校验
			try {
				if (this.beforeUpload) {
					return this.beforeUpload(file);
				}
				// 类型校验
				let type = file.name
					.substr(file.name.lastIndexOf('.') + 1)
					.toLowerCase();
				let isExceedsAccept = !accept.includes(type);
				if (isExceedsAccept) {
					this.$message.error(`上传类型不正确!${type}`);
					return false;
				}

				// 大小校验
				let isExceedsLimit = file.size > size;
				if (isExceedsLimit) {
					this.$message.error(`上传文件大小不能超过 ${size}MB!`);
					return false;
				}

				return true;
			} catch (err) {
				console.log(err);
			}
		},
		// 校验图片尺寸
		checkImgSize(file) {
			return new Promise(resolve => {
				let _URL = window.URL || window.webkitURL;
				let img = new Image();
				img.onload = () => {
					if (this.strict) {
						if (this.aspectRatio) {
							resolve(img.width / img.height == this.aspectRatio);
						} else {
							resolve(img.width == this.width && img.height == this.height);
						}
					} else {
						resolve(img.width <= this.width && img.height <= this.height);
					}
				};
				img.src = _URL.createObjectURL(file);
			});
		},
		/**
		 * @description 自定义上传请求
		 * @param {object} result
		 */
		httpRequest(result) {
			console.log(result);
			this.loading = true;
			// cos上传
			upload(result, uploadOptions.custom)
				.then(res => {
					this.loading = false;
					result.onSuccess(res);
				})
				.catch(err => {
					this.loading = false;
					this.$message.error(err.message);
					result.onError(err);
				});
		},

		/**
		 * @description 文件上传时
		 */
		handleProgress() {
			console.log(111111);
		},

		/**
		 * @description 上传成功
		 * @param {Object} res
		 */
		handleSuccess(res) {
			console.log(11111, res);
			if (this.isCustom) {
				this.$emit('change', res.url);
			} else {
				if (res.statusCode === 0) {
					this.$emit('change', res.data.url);
				} else {
					this.$message.error(res.message);
				}
			}
		},

		/**
		 * @description 上传失败
		 * @param {*} err
		 */
		handleError(err) {
			console.log('上传失败', err);
			this.$message.error('上传失败');
		},

		/**
		 * @description 数量超出限制
		 */
		handleExceed() {
			this.$message.error(`图片数量超出限制`);
		},
	},
};
</script>

<style lang="scss" scoped>
.upload {
	display: inline-block;
	vertical-align: top;
}
</style>
