/*
 * @Author: weiamin weiamin@vchangyi.com
 * @Date: 2023-05-18 09:36:23
 * @LastEditors: weiamin weiamin@vchangyi.com
 * @LastEditTime: 2023-06-07 11:24:00
 * @FilePath: \web-admin\src\mixins\paging.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
	data() {
		return {
			query: {},
			list: [],
			meta: {
				page: 1,
				page_size: 10,
				total: 0,
			},
			page_sizes: [10, 20, 30, 40],
		};
	},
	mounted() {
		if (this.query.stop) return;
		this.getData();
	},
	methods: {
		// 获取数据
		getData() {
			const params = Object.assign(this.filterData({ ...this.query }), {
				page: this.meta.page,
				page_size: this.meta.page_size,
			});
			this.fetchList(params);
		},
		// 筛选参数
		filterData(data) {
			return Object.keys(data).reduce((result, key) => {
				if (
					// not ''
					data[key] !== '' &&
					// not undefined
					data[key] !== undefined &&
					// not null
					data[key] !== null &&
					// not an params array
					(!Array.isArray(data[key]) || data[key].length)
				) {
					result[key] = data[key];
				}
				return result;
			}, {});
		},
		// 搜索
		search() {
			this.meta.page = 1;
			this.getData();
		},
		// 重置
		reset() {
			for (const key in this.query) {
				if (Object.hasOwnProperty.call(this.query, key)) {
					this.query[key] = '';
				}
			}
			this.meta.page = 1;
			this.getData();
		},
		/**
		 * 每页显示几条
		 * val {int} 页数
		 */
		handleSizeChange(val) {
			this.meta.page_size = val;
			this.meta.page = 1;
			this.getData();
		},
		/**
		 * 翻页
		 * val {int} 页码
		 */
		handleCurrentChange(val) {
			this.meta.page = val;
			this.getData();
		},
		/**
		 * 重新获取当前页面
		 */
		resetCurrentPage() {
			if (this.meta.page !== 1 && this.meta.total % this.meta.page_size === 1) {
				this.meta.page = this.meta.page - 1;
			}
			this.getData();
		},
	},
};
