import Vue from 'vue';
import '@/plugins/axios';
import '@/plugins/element';
import '@/icons';
import App from '@/App.vue';
import Store from '@/store';
import Router from '@/router';
import Filters from '@/filters';
import Directives from '@/directives';
import Components from '@/components/public';
import VueClipboard from 'vue-clipboard2';
import cache from '@/utils/cache'; // 缓存
import '@/element-variables.scss';
import 'element-ui/lib/theme-chalk/icon.css';
import LayoutList from '@/layout/layout-list/index.vue';
import LayoutForm from '@/layout/layout-form/index.vue';
import LayoutEmpty from '@/layout/layout-empty/index.vue';
// 全局组件注册
Vue.use(Components);
Vue.component('LayoutList', LayoutList);
Vue.component('LayoutForm', LayoutForm);
Vue.component('LayoutEmpty', LayoutEmpty);
// 全局过滤器注册
Vue.use(Filters);
// 全局指令注册
Vue.use(Directives);

Vue.config.productionTip = false;
//复制内容到剪切板工具
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

Vue.prototype.$cache = cache;

new Vue({
	router: Router,
	store: Store,
	render: h => h(App),
}).$mount('#app');
