<template>
	<el-row class="user" slot="reference">
		<span class="m-r-10">欢迎您，{{ userInfo.name }}</span>
		<el-divider direction="vertical"></el-divider>
		<span @click="logout" class="logout m-l-10">退出登录</span>
	</el-row>
</template>
<script>
import Api from '@/apis/public/common';
import { mapGetters } from 'vuex';

export default {
	name: 'LayoutUser',
	data() {
		return {
			enterpriseNum: 0,
			modelShow: false, //弹窗的控制
		};
	},
	computed: {
		...mapGetters('USER', {
			userInfo: 'getUserInfo',
		}),
		...mapGetters('PERMISSION', {
			ROLES: 'getRoleList',
		}),
	},
	methods: {
		logout() {
			this.$confirm('确定退出当前账户, 是否继续?', '温馨提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					Api.logout({})
						.then(() => {
							this.$cache.clear();
							window.location.href = `${window.location.origin}/login`;
							this.$message.success('退出成功');
						})
						.catch(err => {
							console.log(err);
						});
				})
				.catch(() => {
					this.$message.info('已取消退出');
				});
		},
	},
	mounted() {},
};
</script>
<style lang="scss" scoped>
.logout {
	cursor: pointer;
}
</style>
