import Vue from 'vue';

const http = Vue.axios;
// 接口文档 https://www.tapd.cn/33408319/markdown_wikis/show/#1133408319001019012
let apis = {
	// 登录
	login(data) {
		return http('admin/login', {
			method: 'POST',
			data,
		});
	},
	// 登录
	loginQyApi(data) {
		return http('admin/qy-auth', {
			method: 'POST',
			data,
		});
	},
	// 退出登录
	logout() {
		return http('admin/logout', {
			method: 'POST',
		});
	},
	// 管理员权限列表
	permissionList() {
		return http(`admin/admin/permissionList`, {
			method: 'GET',
		});
	},
	// 二次校验
	loginAuth(params) {
		return http(`/admin/2FA`, {
			method: 'POST',
			data: params,
		});
	},
	// 获取当前登录用户的信息
	currentUserData(data) {
		return http(`/admin/adminByAccount`, {
			method: 'POST',
			data,
		});
	},
	// 获取图形验证码
	getVerCodeImg(data) {
		return http(`/admin/captcha`, {
			method: 'POST',
			data,
		});
	},
	getBackImage() {
		return http(`admin/web`, {
			method: 'GET',
		});
	},
	// 找回密码的设置密码
	setPassword(data) {
		return http(`/admin/setPassword`, {
			method: 'POST',
			data,
		});
	},
	// 获取短信验证码
	sendSetPasswordCode(data) {
		return http(`/admin/sendSetPasswordCode`, {
			method: 'POST',
			data,
		});
	},
	// 校验重置初始密码链接地址是否有效
	checkResetPasswordLink(data) {
		return http(`/admin/checkResetPasswordLink`, {
			method: 'POST',
			data,
		});
	},
	// 初次邮箱激活账号
	setInitialPassword(data) {
		return http(`/admin/setInitialPassword`, {
			method: 'POST',
			data,
		});
	},
	// 已登录账号，修改密码
	updatePassword(data) {
		return http(`/admin/admin-info/updatePassword`, {
			method: 'POST',
			data,
		});
	},
	updateNewPassword(data) {
		return http(`/admin/admin/${data.id}`, {
			method: 'PUT',
			data,
		});
	},
	uploadPic(data) {
		return http(`/admin/upload`, {
			method: 'POST',
			data,
		});
	},
};

export default apis;
