<!--
 * @Author: weiamin weiamin@vchangyi.com
 * @Date: 2023-05-15 13:39:47
 * @LastEditors: weiamin weiamin@vchangyi.com
 * @LastEditTime: 2023-06-05 20:22:47
 * @FilePath: \web-admin\src\views\base\common\login.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div
		class="login"
		:style="{ backgroundImage: 'url(' + webSite.login_background_image + ')' }"
	>
		<div class="login-box">
			<div class="login-content">
				<div class="title">{{ webSite.site_name }}</div>
				<div class="content-box">
					<div class="lc-item">
						<span>账号</span>
						<el-input
							v-model="info.account"
							placeholder="请输入账号"
							:class="[infoFlag.account ? 'input-red' : '']"
						></el-input>
					</div>
					<div class="lc-item">
						<span>密码</span>
						<el-input
							@keyup.enter.native="login"
							v-model="info.password"
							:type="pwdFlag ? 'text' : 'password'"
							placeholder="请输入密码"
							:class="[infoFlag.password ? 'input-red' : '']"
						>
							<img
								slot="suffix"
								:src="pwdImg"
								alt="pwd"
								class="eye"
								@click="pwdFlag = !pwdFlag"
							/>
						</el-input>
					</div>
					<div class="lc-item">
						<span>验证码</span>
						<div class="ver-code">
							<div class="df">
								<el-input
									@keyup.enter.native="login"
									v-model="info.captcha"
									placeholder="请输入验证码"
									style="width: 60%; margin-right: 5%"
									:class="[infoFlag.captcha ? 'input-red' : '']"
								></el-input>
								<img
									@click="getVerCodeImg"
									:src="verCode.img"
									alt="验证码"
									style="width: 35%; height: 40px"
								/>
							</div>
							<span class="forget-pwd"
								><span @click="goForgetPwd">忘记密码</span></span
							>
						</div>
					</div>
					<div class="error-tip">
						<span v-for="item in errorTip" :key="item">{{ item }}</span>
					</div>
					<el-row>
						<el-button
							type="primary"
							size="large"
							class="login-button"
							@click="login"
							>登录</el-button
						>
					</el-row>
					<el-row>
						<el-button size="large" class="login-button" @click="qrcodeLogin"
							>扫码登录</el-button
						>
					</el-row>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Api from '@/apis/public/common';
import Permission from '@/mixins/Permission';
// import setting from "@/setting";
import ceye from '@/assets/login/ceye.png';
import oeye from '@/assets/login/oeye.png';
import login_background_image from '@/assets/login-bac.jpg';
import system_logo from '@/assets/vchangyi.png';
// import { findTreeNode } from '@/utils/tree';

export default {
	name: 'Login',
	data() {
		return {
			// 校验信息
			info: {
				account: '',
				password: '',
				captcha: '',
			},
			// 校验信息状态
			infoFlag: {
				account: false,
				password: false,
				captcha: false,
			},
			errorTip: [], // 错误提示信息
			verCode: {}, // 图片验证码
			pwdFlag: false, // 密码框显示与否
			webSite: {
				site_name: '管理系统',
				login_background_image,
				system_logo,
			},
		};
	},
	mixins: [Permission],
	mounted() {
		localStorage.clear();
		this.getVerCodeImg();
		// this.getBackImage();
	},
	computed: {
		...mapGetters('MENU', {
			menu: 'getMenu',
			firstRoute: 'getFirstRoute',
		}),
		pwdImg() {
			return this.pwdFlag ? oeye : ceye;
		},
	},
	methods: {
		...mapActions('USER', ['setUserInfo']),
		...mapActions('PERMISSION', ['setRoles']),
		// 校验
		checkInfo() {
			// 重置状态
			this.errorTip = [];
			for (const key in this.infoFlag) {
				this.infoFlag[key] = false;
			}

			if (!this.info.account) {
				this.errorTip.push('请输入账号');
				this.infoFlag.account = true;
				return false;
			}
			if (!this.info.password) {
				this.errorTip.push('请输入密码');
				this.infoFlag.password = true;
				return false;
			}
			if (!this.info.captcha) {
				this.errorTip.push('请输入验证码');
				this.infoFlag.captcha = true;
				return false;
			}
			return true;
		},
		// 登录
		async login() {
			if (!this.checkInfo()) return;
			try {
				const res = await Api.login({
					...this.info,
					...{ key: this.verCode.key },
				});
				// if (res.data.roles[0].role_name === '无权限角色') {
				// 	this.$message.error('无权限访问');
				// 	return;
				// }
				// 保存登录信息
				await this.setUserInfo(res.data);
				await this.setRoles(res.data.roles);
				// 是否需要谷歌二次校验
				if (res.data.google2fa_enable === 1) {
					this.$router.replace({
						path: 'login-middle',
					});
				} else {
					// 更新权限
					await this.permissionUpdate();
					// 页面跳转
					this.$router.push(this.firstRoute);
				}
			} catch (error) {
				console.log(error);
				this.getVerCodeImg();
				this.errorTip.push(error.data.message);
			}
		},
		// 关闭admin/2FA
		// 开启admin/2FA
		// 获取验证码
		async getVerCodeImg() {
			this.info.captcha = '';
			let response = await Api.getVerCodeImg();
			this.verCode = response.data;
		},
		async getBackImage() {
			let response = await Api.getBackImage();
			this.webSite.site_name = response.data.site_name
				? response.data.site_name
				: this.webSite.site_name;
			this.webSite.login_background_image = response.data.login_background_image
				? response.data.login_background_image
				: this.webSite.login_background_image;
			this.webSite.system_logo = response.data.system_logo
				? response.data.system_logo
				: this.webSite.system_logo;
			localStorage.setItem('websit', JSON.stringify(response.data));
		},
		// 忘记密码
		goForgetPwd() {
			this.$router.push('/forget-password');
		},
		qrcodeLogin() {
			this.$router.push('/login-qrcode');
		},
	},
};
</script>

<style lang="scss" scoped>
$font-color: #292a2b;
$font-18: 2.2vh;
$font-26: 3.6vh;
::v-deep .el-input--small .el-input__inner {
	height: 40px;
	line-height: 40px;
}
::v-deep .el-input__inner:focus {
	box-shadow: 0 2px 5px rgba(91, 91, 91, 0.1);
}
.login {
	width: 100%;
	height: 100vh;
	background: url($login-bg);
	background-repeat: repeat;
	background-size: contain;
	display: flex;
	justify-content: flex-end;
}
.login-box {
	position: relative;
	width: 37.5%;
	min-width: 476px;
	height: 100%;
	background-color: #ffffff;
	border-radius: 35px 0 0 0;
	.login-content {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 20;
		width: 80%;
		margin-left: 12.2%;
		padding: 18vh 4vh 0;
		height: 60vh;
		border-radius: 4px;
		box-sizing: border-box;
		.title {
			font-size: $font-26;
			font-weight: 600;
			margin-bottom: 8vh;
		}
		.content-box {
			min-height: 340px;
			color: $font-color;
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			.lc-item {
				font-size: $font-18;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin-bottom: 4vh;
				box-sizing: content-box;
				span {
					margin-bottom: 2vh;
				}
				.eye {
					width: 21px;
					height: 14px;
					margin-top: 14px;
					margin-right: 6px;
					font-size: 18px;
					cursor: pointer;
				}
				.ver-code {
					display: flex;
					flex-direction: column;
					.df {
						display: flex;
					}
					.forget-pwd {
						display: block;
						color: $color-main;
						font-size: 10px;
						margin-top: 5px;
						text-align: right;
						span {
							cursor: pointer;
						}
					}
				}
				.input-red ::v-deep .el-input__inner {
					border-color: $color-danger;
				}
			}
			.error-tip {
				height: 20px;
				font-size: 10px;
				color: $color-danger;
			}
			.login-button {
				width: 100%;
				// height: 40px;
				font-weight: normal;
				margin-top: 3%;
				letter-spacing: 6px;
				// box-shadow: 0 11px 21px #d6e1ff;
			}
		}
	}
}
</style>
