<template>
	<div class="no-page">
		<img
			class="no-page-img"
			src="https://oss-crs.vchangyi.com/wechat/no-contacts-icon.png"
			alt=""
		/>
		<div class="no-page-title">当前尚未开通相关服务，请联系企业管理员</div>
	</div>
</template>
<script>
export default {
	name: 'NoApp',
};
</script>
<style lang="scss" scoped>
.no-page {
	background: #f5f7fa;
	position: relative;
	height: 100%;
	display: flex;
	align-content: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;

	.no-page-img {
		display: block;
		height: 275px;
		width: 360px;
		left: 0;
		right: 0;
		margin: auto;
		margin-bottom: 0;
		margin-top: 0;
	}

	.no-page-title {
		color: #606266;
		font-size: 20px;
		display: block;
	}
}
</style>
