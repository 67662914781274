const { processEnv } = require('./utils/env');

module.exports = {
	title: '管理系统',
	/** 上传配置 */
	uploadOptions: {
		/** 自定义上传 eg: default | cos | oss | obs */
		custom: 'oss',
		/** 上传地址 */
		action: processEnv.VUE_APP_API_ROOT + '/admin/upload',
		/** 上传 */
		name: 'file',
		/** 文件类型 */
		accept: {
			image: 'jpeg,jpg,png',
			video: 'mp4',
		},
		/** 文件大小限制（M） */
		size: {
			image: 2,
			video: 200,
		},
	},
	/** 缓存关键字 */
	cacheOptions: {
		/** 权限信息 */
		PERMISSION: 'APP_PERMISSION',
		/** 所有菜单 */
		MENU: 'APP_MENU',
		/** 面包屑数据 */
		BREAD_LIST: 'APP_BREAD_LIST',
		/** 菜单数据 */
		MENU_LIST: 'APP_MENU_LIST',
		/** 按钮数据 */
		BUTTON_LIST: 'APP_BUTTON_LIST',
		/** 视图数据 */
		VIEW_LIST: 'APP_VIEW_LIST',
		/** 接口数据 */
		API_LIST: 'APP_API_LIST',
		/** token数据 */
		TOKEN: 'APP_TOKEN',
		/** 用户数据 */
		USER: 'APP_USER',
		/** 系统主题设置 */
		THEME: 'APP_THEME',
		/** 系统菜单模式 */
		MODE: 'APP_MODE',
	},
	/** 缓存配置 */
	cacheConfig: {
		/** 使用会话存储 */
		session: true,
		/** 存储时常 */
		expire: 0,
	},
	/** 菜单展示模式 horizontal(水平) / vertical(垂直) / thr(顶部菜单 + 左侧菜单) */
	mode: 'thr',
	/** 主题配置 */
	theme: {
		/** 系统主题色 */
		color_primary: '#409EFF',
		/** 菜单背景色 */
		side_bar_bg: '#001529',
		/** 激活菜单颜色 */
		active_menu_bg: '#409EFF',
		/** 默认菜单字体颜色 */
		base_text_color: '#bfcbd8',
		/** 激活菜单字体颜色 */
		active_text_color: '#ffffff',
	},
	/** 环境配置 */
	processEnv,
};
