<template>
	<el-dialog :visible.sync="visible" title="请选择员工" top="10vh">
		<!-- 选择成员 -->
		<el-row class="header p-b-10">
			<el-input
				class="m-r-10"
				v-model="query.user_name"
				prefix-icon="el-icon-search"
				placeholder="请输入员工姓名"
			></el-input>
			<el-input
				class="m-r-10"
				v-model="query.department_name"
				prefix-icon="el-icon-search"
				placeholder="请输入部门名称"
			></el-input>
			<el-button type="primary" @click="search">查询</el-button>
			<el-button @click="reset">重置</el-button>
		</el-row>
		<el-row class="content m-t-10" :gutter="20">
			<el-col :span="8">
				<el-row class="department">
					<el-tree
						class="filter-tree"
						:data="departmentList"
						:props="defaultProps"
						lazy
						:load="loadNode"
						ref="tree"
						@node-click="handleNodeClick"
					>
					</el-tree>
				</el-row>
			</el-col>
			<el-col :span="16">
				<el-table
					:data="userList"
					stripe
					max-height="50vh"
					tooltip-effect="dark"
				>
					<el-table-column label="员工姓名" prop="name">
						<template slot-scope="scope">
							<el-row class="user">
								<el-image class="avatar m-r-5" :src="scope.row.user_avatar">
									<i slot="error" class="el-icon-user"></i>
								</el-image>
								<span>{{ scope.row.user_name }}</span>
							</el-row>
						</template>
					</el-table-column>
					<el-table-column label="员工ID" prop="userid"></el-table-column>
					<el-table-column label="选择" width="100">
						<template slot-scope="scope">
							<el-radio
								v-model="select_user_id"
								:label="scope.row.userid"
								@input="handleRadioChange(scope.row)"
							></el-radio>
						</template>
					</el-table-column>
					<template slot="empty">
						<layout-empty></layout-empty>
					</template>
				</el-table>
				<el-row v-if="meta.total > meta.page_size" class="footer m-t-10">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="meta.page"
						:page-sizes="page_sizes"
						:page-size="meta.page_size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="meta.total"
					></el-pagination>
				</el-row>
				<el-row class="bottom">
					<el-button @click="handleClose">取消</el-button>
					<el-button type="primary" @click="handleConfirm">确定</el-button>
				</el-row>
			</el-col>
		</el-row>
	</el-dialog>
</template>
<script>
import Api from '@/apis/system/staff';
import Pagination from '@/mixins/paging';
export default {
	name: 'ComSelectUser',
	mixins: [Pagination],
	data() {
		return {
			query: {
				stop: true,
				/** 部门ID */
				department_id: '',
				/** 部门名称 */
				department_name: '',
				/** 员工姓名 */
				user_name: '',
			},
			/** 部门列表 */
			departmentList: [],
			/** 员工列表 */
			userList: [],
			/** 树形结构配置 */
			defaultProps: {
				children: 'children',
				label: 'name',
			},
			/** 选中的员工ID */
			select_user_id: '',
			/** 选中的员工 */
			selected: [],
			/** 显示隐藏 */
			visible: false,
		};
	},
	mounted() {},
	methods: {
		async loadNode(node, resolve) {
			const params = { page_size: 1000 };
			if (node.level > 0) {
				params.parent_id = node.data.department_id;
			}
			let res = await Api.getDepartmentListApi(params);
			return resolve(res.data.list);
		},
		/** 获取部门列表 */
		async getDepartmentList() {
			try {
				let res = await Api.getDepartmentListApi({ page_size: 1000 });
				this.departmentList = res.data.list;
			} catch (error) {
				console.log(error);
			}
		},
		/** 获取部门下面的员工列表 */
		async fetchList(params) {
			try {
				let res = await Api.getUserListApi(params);
				this.userList = res.data.list;
			} catch (error) {
				console.log(error);
			}
		},
		/**
		 * @description 树节点点击事件
		 * @param {object} data
		 */
		handleNodeClick(data) {
			this.query.department_id = data.department_id;
			this.getData();
		},
		/**
		 * @description 单选框点击事件
		 * @param {string} value
		 */
		handleRadioChange(value) {
			console.log(value);
			this.selected.push(value);
		},
		/**
		 * @description 确定按钮点击事件
		 */
		handleConfirm() {
			this.$emit('confirm', this.selected);
			this.handleClose();
		},
		/**
		 * @description 显示弹窗
		 */
		handleShow() {
			this.visible = true;
		},
		/**
		 * @description 关闭弹窗
		 */
		handleClose() {
			this.visible = false;
		},
		/**
		 * @description 搜索
		 */
		search() {
			this.meta.page = 1;
			this.query.department_id = '';
			this.getData();
		},
	},
};
</script>

<style scoped lang="scss">
.header {
	border-bottom: 1px solid #ebeef5;
}
.footer {
	text-align: right;
	::v-deep .el-input {
		width: 50px !important;
	}
	::v-deep .el-select .el-input {
		width: 100px !important;
	}
}

.content {
	height: 60vh;
	.el-col {
		height: 100%;
		position: relative;
	}

	.bottom {
		position: absolute;
		bottom: 0;
		text-align: right;
		width: 100%;
		padding: 0 30px;
	}
}

.department {
	height: 100%;
	// background-color: #f9f9f9;
	overflow-y: scroll;
	padding: 10px 0;

	::v-deep .el-tree {
		height: 100%;
		border: none;
		background-color: transparent;
	}
}

.user {
	display: flex;
	align-items: center;
}
.avatar {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	line-height: 30px;
	text-align: center;
	background-color: #ebeef5;
	font-size: 16px;
}
::v-deep .el-table td {
	padding: 10px 0;
}
::v-deep .el-radio__label {
	display: none;
}
</style>
