import { getUserInfo, setTokenInfo, setUserInfo } from '@/utils/login/storage';

//  单一状态树
const state = {
	USERINFO: null,
};

// getters 暴露出去的获取 state 的计算属性
const getters = {
	getUserInfo: state => {
		if (!state.USERINFO) {
			state.USERINFO = getUserInfo();
		}
		// state.USERINFO = {"admin_id":127769914645568,"version":3,"hash":"f10c36631e934c9c60242b09b3105b41","account":"yanghucheng@vchangyi.com","account_email":"yanghucheng@vchangyi.com","account_expire_at":1645199999,"account_global_roaming":"","account_mobile":"15891652337","account_name":"yanghucheng@vchangyi.com","account_start_at":1608652800,"account_status":1,"address":"","admin_available_system":"[1]","contact_mobile":"15891652337","contact_name":"杨虎成","contact_status":2,"corp_id":"1231","crs_status":1,"domain":"8B7C9B077F0000016682A4460BF6A488","enterprise_id":127769914577088,"enterprise_role_list":[],"guide_permissions":null,"guide_roles":null,"identifier":"","is_split_flow":0,"is_super":1,"logo":"https://wx.qlogo.cn/mmopen/z1ZImOHsgJfFWj0oP1klFJV0kr9S6agVkkvQChIlAZ5ENegdXF7sUU3XGkJoGAV80NRicM9bSoZYiaM8yh18X4cZUMGo4NxkUC/0","name":"畅鲟小鸽子","range_type":1,"role":1,"serve_type":0,"shop_name":"畅鲟小鸽子","store_status":1,"version_tag":"正式版","qr_code":"https://img-crs.vchangyi.com/2021/01/18/70213d0d7e0821aa538a5a2f7c3a5949.png"}
		return state.USERINFO;
	},
};

// mutations 私有化方法，只能被 actions 调用， 可以直接变更状态
const mutations = {
	setUserInfo(state, data) {
		setUserInfo(data);
		setTokenInfo(data.Authorization);
		state.USERINFO = data;
	},
	// 更新domain
	setDomain(state, data) {
		state.USERINFO.enterprise.domain = data;
	},
};

// actions 暴露到外部的方法 不直接变更状态
const actions = {
	setUserInfo({ commit }, data) {
		commit('setUserInfo', data);
	},
	// 更新domain
	setDomain({ commit }, data) {
		commit('setDomain', data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
