<template>
	<div
		class="login"
		:style="{ backgroundImage: 'url(' + webSite.login_background_image + ')' }"
	>
		<div class="login-box">
			<div class="login-content">
				<div class="title">{{ webSite.site_name }}</div>
				<div id="ww_login" class="login-qrcode"></div>
				<el-row>
					<el-button class="login-button" size="large" @click="toLogin"
						>账号登录</el-button
					>
				</el-row>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Api from '@/apis/public/common';
import Permission from '@/mixins/Permission';
// import setting from "@/setting";
import ceye from '@/assets/login/ceye.png';
import oeye from '@/assets/login/oeye.png';
import login_background_image from '@/assets/login-bac.jpg';
import system_logo from '@/assets/vchangyi.png';
import { init } from '@/utils/wwLogin';
// import { findTreeNode } from '@/utils/tree';

export default {
	name: 'Login',
	data() {
		return {
			// 校验信息
			info: {
				account: '',
				password: '',
				captcha: '',
			},
			// 校验信息状态
			infoFlag: {
				account: false,
				password: false,
				captcha: false,
			},
			errorTip: [], // 错误提示信息
			verCode: {}, // 图片验证码
			pwdFlag: false, // 密码框显示与否
			webSite: {
				site_name: '管理系统',
				login_background_image,
				system_logo,
			},
		};
	},
	mixins: [Permission],
	mounted() {
		localStorage.clear();
		this.login();
	},
	computed: {
		...mapGetters('MENU', {
			menu: 'getMenu',
			firstRoute: 'getFirstRoute',
		}),
		pwdImg() {
			return this.pwdFlag ? oeye : ceye;
		},
	},
	methods: {
		...mapActions('USER', ['setUserInfo']),
		...mapActions('PERMISSION', ['setRoles']),
		// 登录
		async login() {
			try {
				const { code } = await init();
				const res = await Api.loginQyApi({ code });
				// 保存登录信息
				await this.setUserInfo(res.data);
				await this.setRoles(res.data.roles);
				// 是否需要谷歌二次校验
				if (res.data.google2fa_enable === 1) {
					this.$router.replace({
						path: 'login-middle',
					});
				} else {
					// 更新权限
					await this.permissionUpdate();
					// 页面跳转
					this.$router.push(this.firstRoute);
				}
			} catch (error) {
				console.log(error);
				this.getVerCodeImg();
				this.errorTip.push(error.data.message);
			}
		},
		// 关闭admin/2FA
		// 开启admin/2FA
		// 获取验证码
		async getVerCodeImg() {
			this.info.captcha = '';
			let response = await Api.getVerCodeImg();
			this.verCode = response.data;
		},
		async getBackImage() {
			let response = await Api.getBackImage();
			this.webSite.site_name = response.data.site_name
				? response.data.site_name
				: this.webSite.site_name;
			this.webSite.login_background_image = response.data.login_background_image
				? response.data.login_background_image
				: this.webSite.login_background_image;
			this.webSite.system_logo = response.data.system_logo
				? response.data.system_logo
				: this.webSite.system_logo;
			localStorage.setItem('websit', JSON.stringify(response.data));
		},
		// 忘记密码
		goForgetPwd() {
			this.$router.push('/forget-password');
		},
		toLogin() {
			this.$router.push('/login');
		},
	},
};
</script>

<style lang="scss" scoped>
$font-color: #292a2b;
$font-18: 2.2vh;
$font-26: 3.6vh;
::v-deep .el-input--small .el-input__inner {
	height: 40px;
	line-height: 40px;
}
::v-deep .el-input__inner:focus {
	box-shadow: 0 2px 5px rgba(91, 91, 91, 0.1);
}
.login {
	width: 100%;
	height: 100vh;
	background: url($login-bg);
	background-repeat: repeat;
	background-size: contain;
	display: flex;
	justify-content: flex-end;
	text-align: center;
}
.login-box {
	position: relative;
	width: 37.5%;
	min-width: 476px;
	height: 100%;
	background-color: #ffffff;
	border-radius: 35px 0 0 0;
	.login-content {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 20;
		width: 80%;
		margin-left: 12.2%;
		padding: 18vh 4vh 0;
		height: 60vh;
		border-radius: 4px;
		box-sizing: border-box;
		.title {
			font-size: $font-26;
			font-weight: 600;
			margin-bottom: 8vh;
			text-align: center;
		}
		.login-qrcode {
			// position: absolute;
			// left: 50%;
			// top: 40%;
			// transform: translate(-50%, -50%);
			width: 320px;
			height: 380px;
			box-shadow: 0px 6px 12px 4px rgba(36, 72, 119, 0.1);
			margin: auto;
		}
	}
}

.login-button {
	width: 320px;
	margin-top: 30px;
}
</style>
