/**
 * 树结构转数组
 *
 * @param {*} array
 * @param {*} key
 * @returns
 */
export const flatten = (array, key) => {
	return [].concat(
		...array.map(item => [].concat(item, ...flatten(item[key] || [], key))),
	);
};

/**
 * 树节点筛选
 *
 * @param {*} nodes
 * @param {*} callback
 * @returns
 */
export const filterMenu = (nodes, callback) => {
	// 条件就是节点的title过滤关键字
	if (!(nodes && nodes.length)) {
		return [];
	}
	const newChildren = [];
	for (const node of nodes) {
		if (callback(node)) {
			newChildren.push(node);
			node.children = filterMenu(node.children, callback);
		} else {
			newChildren.push(...filterMenu(node.children, callback));
		}
	}
	return newChildren.length ? newChildren : [];
};

// 检测 map end
const result = (ary, resolve, target) => {
	if (ary.length === 0) {
		resolve(target);
	}
	return ary.length !== 0;
};

/**
 * 深度遍历树结构
 *
 * @param {*} {
 *   data,
 *   childField = "children",
 *   callback
 * }
 * @returns
 */
export const deepMapPromise = ({ data, childField = 'children', callback }) => {
	const target = data;
	return new Promise(resolve => {
		if (target && childField) {
			const nodes = [];
			let node = {};
			// 类型判断
			target.constructor === Array
				? (node[childField] = target)
				: (node = target);
			const stack = []; // 同来存放将来要访问的节点
			stack.push(node);
			while (result(stack, resolve, target)) {
				const item = stack.pop(); // 正在访问的节点
				nodes.push(item);
				if (callback) {
					// 回调函数处理 item
					callback(item);
				}
				const childrens = item[childField];
				if (childrens) {
					for (let i = childrens.length - 1; i >= 0; i--) {
						stack.push(childrens[i]);
					}
				}
			}
		}
	});
};

/**
 * 获取第一子节点
 *
 * @param {*} node
 * @returns
 */
export const getFirstChildMenu = node => {
	if (node.children && node.children.length > 0) {
		const tmp = node.children[0];
		return getFirstChildMenu(tmp);
	} else {
		return node;
	}
};

/**
 * 查找符合条件的节点
 *
 * @param {*} tree
 * @param {*} func
 * @returns
 */
export const findMenu = (tree, func) => {
	for (const data of tree) {
		if (func(data)) return data;
		if (data.children) {
			const res = findMenu(data.children, func);
			if (res) return res;
		}
	}
	return null;
};

const fomat = out => {
	const all = out
		.filter(Boolean)
		.sort((a, b) => {
			return a.ids[1] - b.ids[1];
		})
		.sort((a, b) => {
			return a.ids[0] - b.ids[0];
		});

	const list = all;

	// 数据权限
	const data = all.filter(item => item.type == 0);
	// 操作权限
	const operation = all.filter(item => item.type == 3);
	// 模块权限
	const views = all
		.filter(item => !(item.types.length == 2 && item.types[0] == item.types[1]))
		.filter(item => item.type == 2 || item.type == 4)
		.map((item, index, arr) => {
			// 一级菜单
			const idx_0 = item.ids[0];
			const _list = arr.filter(value => value.ids[0] == idx_0);
			item.prerowspan = item.prerowspan || {};
			item.rowspan = item.rowspan || {};
			item.rowspan[idx_0] = _list.length;
			if (index - 1 >= 0) {
				item.prerowspan[idx_0] = arr[index - 1].rowspan[idx_0] || 0;
			} else {
				item.prerowspan[idx_0] = 0;
			}

			// 二级菜单
			const idx_1 = item.ids[1];
			const _list_1 = arr.filter(value => value.ids[1] == idx_1);
			item.rowspan[idx_1] = _list_1.length;
			if (index - 1 >= 0) {
				item.prerowspan[idx_1] = arr[index - 1].rowspan[idx_1];
			} else {
				item.prerowspan[idx_1] = 0;
			}

			// 操作
			item.operation = operation.filter(v => v.ids[1] == item.ids[1]);
			// 数据
			item.data = data.filter(v => v.ids[1] == item.ids[1]);

			return item;
		});

	return { list, operation, views, data };
};

export const tree2Array = options => {
	const { source, keys = {}, callback } = options;
	const { current = 'id', parent = 'parent_id', child = 'children' } = keys;
	let temp = []; // 设置临时数组，用来存放队列
	const out = []; // 设置输出数组，用来存放要输出的一维数组

	if (source.constructor === Array) {
		temp.push({
			[`${child}`]: source,
			[`${current}`]: 0,
		});
	} else {
		temp = source;
	}

	return new Promise(resolve => {
		while (temp.length > 0) {
			const first = temp.shift();
			const children = first[child];

			if (children && children.length > 0) {
				const len = children.length;

				for (let i = 0; i < len; i++) {
					const item = children[i];
					temp.push(item);
					item[parent] = first[current];
					if (callback) {
						out.push(callback(item, first));
					} else {
						out.push(item);
					}
				}
			}
			if (temp.length == 1) {
				resolve(fomat(out));
				break;
			}
		}
	});
};

export function mergeArray(arr1, arr2) {
	var _arr = new Array();
	for (let i = 0; i < arr1.length; i++) {
		_arr.push(arr1[i]);
	}
	for (let i = 0; i < arr2.length; i++) {
		var flag = true;
		for (var j = 0; j < arr1.length; j++) {
			if (arr2[i] == arr1[j]) {
				flag = false;
				break;
			}
		}
		if (flag) {
			_arr.push(arr2[i]);
		}
	}
	return _arr;
}
