<template>
	<el-breadcrumb class="breadcrumb">
		<transition-group name="breadcrumb">
			<template v-for="item in list">
				<el-breadcrumb-item :key="item.key">
					{{ item.name }}
				</el-breadcrumb-item>
			</template>
		</transition-group>
	</el-breadcrumb>
</template>
<script>
import { filterTreeNode, treeToList } from '@/utils/tree';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			list: [],
		};
	},
	computed: {
		...mapGetters('MENU', {
			menuList: 'getMenuList',
			breadList: 'getBreadList',
		}),
	},
	watch: {
		breadList: {
			handler() {
				this.init();
			},
		},
	},
	mounted() {
		this.init();
	},
	methods: {
		/** 初始化 */
		init() {
			const { key } = this.$route.meta;
			this.list = treeToList([
				...filterTreeNode(
					[...this.menuList],
					item => key.indexOf(item.key) !== -1,
				),
			]);
			this.list.push(...this.breadList);
		},
	},
};
</script>
