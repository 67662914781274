'use strict';

const { cacheConfig } = require('@/setting');

Object.defineProperty(exports, '__esModule', { value: true });
exports.Cache = void 0;
var Cache = /** @class */ (function () {
	function Cache(options) {
		if (options === void 0) {
			options = {};
		}
		this.cacheExpire = '_expire_2023_04_20_00_00';
		this.expire = options.expire || 0;
		this.session = options.session || false;
	}
	Cache.prototype.cacheOptionsetHandler = function (key, value) {
		if (this.session) {
			sessionStorage.setItem(key, value);
		} else {
			localStorage.setItem(key, value);
		}
	};
	Cache.prototype.cacheGetHandler = function (key) {
		if (this.session) {
			return sessionStorage.getItem(key);
		} else {
			return localStorage.getItem(key);
		}
	};
	Cache.prototype.cacheClearHandler = function (key) {
		if (this.session) {
			sessionStorage.removeItem(key);
		} else {
			localStorage.removeItem(key);
		}
	};
	/**
	 * 获取当前时间
	 * @returns 时间戳
	 */
	Cache.prototype.time = function () {
		return Math.floor(Date.now() / 1000);
	};
	/**
	 * 设置过期时间缓存
	 * @param key
	 * @param expire
	 */
	Cache.prototype.setExpireCache = function (key, expire) {
		expire = expire !== undefined ? expire : this.expire;
		if (expire) {
			var cacheKey = key + this.cacheExpire;
			var cacheValue = (this.time() + expire).toString();
			this.cacheOptionsetHandler(cacheKey, cacheValue);
		}
	};
	/**
	 * 缓存是否过期,过期自动删除
	 * @param key
	 * @param remove true = 删除,false = 不删除
	 */
	Cache.prototype.getExpireCache = function (key, remove) {
		try {
			var time = this.cacheGetHandler(key + this.cacheExpire);
			if (time) {
				var newTime = parseInt(time);
				if (newTime && !Number.isNaN(newTime) && newTime < this.time()) {
					if (remove === undefined || remove === true) {
						this.cacheClearHandler(key);
						this.cacheClearHandler(key + this.cacheExpire);
					}
					return false;
				} else return true;
			} else {
				return !!this.cacheGetHandler(key);
			}
		} catch (e) {
			return false;
		}
	};
	/**
	 * 设置缓存
	 * @param key
	 * @param data
	 * @param expire
	 */
	Cache.prototype.set = function (key, data, expire) {
		var value = typeof data === 'object' ? JSON.stringify(data) : data;
		try {
			this.setExpireCache(key, expire);
			this.cacheOptionsetHandler(key, value);
			return true;
		} catch (e) {
			console.log(e);
			return false;
		}
	};
	/**
	 * 检测缓存是否存在
	 * @param key
	 */
	Cache.prototype.has = function (key) {
		return this.getExpireCache(key);
	};
	/**
	 * 获取缓存
	 * @param key
	 * @param $default
	 * @param expire
	 */
	Cache.prototype.get = function (key, $default, expire) {
		try {
			var isBe = this.getExpireCache(key);
			var data = this.cacheGetHandler(key);
			// 如果有缓存，返回缓存数据
			if (data && isBe) {
				// 是否格式化
				if (typeof $default === 'boolean') {
					return JSON.parse(data);
				} else {
					return data;
				}
			} else if ($default) {
				// 如果没有缓存 可以重新存入新的值
				if (typeof $default === 'function') {
					var value = $default();
					this.set(key, value, expire);
					return value;
				} else {
					this.set(key, $default, expire);
					return $default;
				}
			}
		} catch (e) {
			return null;
		}
	};
	/**
	 * 删除缓存
	 * @param  key
	 */
	Cache.prototype.clear = function (key) {
		try {
			var cacheValue = this.cacheGetHandler(key + this.cacheExpire);
			if (cacheValue) {
				this.cacheClearHandler(key + this.cacheExpire);
			}
			this.cacheClearHandler(key);
			return true;
		} catch (e) {
			return false;
		}
	};
	return Cache;
})();
exports.Cache = Cache;
exports.default = new Cache(cacheConfig);
