import cache from '@/utils/cache';
import { cacheOptions, theme, mode } from '@/setting';
//  单一状态树
const state = {
	mode: mode,
	theme: { ...theme },
	collapse: false,
};

// getters 暴露出去的获取 state 的计算属性
const getters = {
	getThemeStyle: state => {
		const data = cache.get(cacheOptions.THEME) || state.theme;
		return Object.keys(data).reduce((value, current) => {
			const key = `--${current.replaceAll('_', '-')}`;
			value[key] = data[current];
			return value;
		}, {});
	},
	getTheme: state => {
		return cache.get(cacheOptions.THEME) || state.theme;
	},
	getMode: state => {
		return cache.get(cacheOptions.MODE) || state.mode;
	},
	getCollapse: state => {
		return state.collapse;
	},
};

// mutations 私有化方法，只能被 actions 调用， 可以直接变更状态
const mutations = {
	setTheme(state, data) {
		cache.set(cacheOptions.THEME, data);
		state.theme = data;
	},
	setMode(state, data) {
		cache.set(cacheOptions.MODE, data);
		state.mode = data;
	},
	setCollapse(state, data) {
		state.collapse = data;
	},
};

// actions 暴露到外部的方法 不直接变更状态
const actions = {
	setTheme({ commit }, data) {
		commit('setTheme', data);
	},
	setMode({ commit }, data) {
		commit('setMode', data);
	},
	setCollapse({ commit }, data) {
		commit('setCollapse', data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
