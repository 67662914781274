import cache from '@/utils/cache';
import { cacheOptions } from '@/setting';

/** 设置token信息 */
export function setTokenInfo(data) {
	cache.set(cacheOptions.TOKEN, data);
}
/** 获取token信息 */
export function getTokenInfo() {
	return cache.get(cacheOptions.TOKEN) || '';
}
/** 设置用户信息 */
export function setUserInfo(data) {
	cache.set(cacheOptions.USER, data);
}
/** 获取用户信息 */
export function getUserInfo() {
	return cache.get(cacheOptions.USER, true) || '';
}
/** 设置菜单信息 */
export function setPermissionInfo(data) {
	cache.set(cacheOptions.PERMISSION, data);
}
/** 获取菜单信息 */
export function getPermissionInfo() {
	return cache.get(cacheOptions.PERMISSION, true) || [];
}
/** 设置菜单信息 */
export function setMenuInfo(data) {
	cache.set(cacheOptions.MENU_LIST, data);
}
/** 获取菜单信息 */
export function getMenuInfo() {
	return cache.get(cacheOptions.MENU_LIST, true) || [];
}
/** 设置按钮信息 */
export function setButtonInfo(data) {
	cache.set(cacheOptions.BUTTON_LIST, data);
}
/** 获取按钮信息 */
export function getButtonInfo() {
	return cache.get(cacheOptions.BUTTON_LIST, true) || [];
}
/** 设置视图信息 */
export function setViewInfo(data) {
	cache.set(cacheOptions.VIEW_LIST, data);
}
/** 获取视图信息 */
export function getViewInfo() {
	return cache.get(cacheOptions.VIEW_LIST, true) || [];
}
/** 设置接口信息 */
export function setApiInfo(data) {
	cache.set(cacheOptions.API_LIST, data);
}
/** 获取接口信息 */
export function getApiInfo() {
	return cache.get(cacheOptions.API_LIST, true) || [];
}
