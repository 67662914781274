<template>
	<el-row class="layout-form">
		<!-- header -->
		<el-row class="layout-header">
			<slot v-if="$slots['header']" name="header"></slot>
			<template v-else>
				<el-row class="layout-title">
					<el-button
						v-if="showBack"
						type="text-default"
						icon="el-icon-arrow-left"
						@click="$router.go(-1)"
					></el-button>
					<span class="back" v-if="showBack" @click="$router.go(-1)">返回</span>
					<el-divider v-if="showBack" direction="vertical"></el-divider>
					<span>{{ title || $route.meta.title }}</span>
				</el-row>
				<el-row class="layout-operate">
					<slot name="operate"></slot>
				</el-row>
			</template>
		</el-row>
		<!-- content -->
		<el-row class="layout-content">
			<slot></slot>
			<slot name="content"></slot>
			<!-- footer -->
			<el-row v-if="$slots['footer']" class="layout-footer">
				<slot name="footer"></slot>
			</el-row>
		</el-row>
	</el-row>
</template>
<script>
import { mapActions } from 'vuex';

export default {
	name: 'LayoutForm',
	mixins: [],
	props: {
		title: {
			type: String,
		},
		showBack: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {};
	},
	mounted() {
		this.init();
	},
	methods: {
		...mapActions('MENU', ['setBreadList']),
		init() {
			if (this.title) {
				this.setBreadList([
					{
						name: this.title,
						key: '',
					},
				]);
			} else {
				this.setBreadList([]);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.layout {
	&-header {
		height: 40px;
		line-height: 40px;
		display: flex;
		align-items: center;
	}
	&-title {
		font-size: 16px;
		display: flex;
		align-items: center;
		font-weight: bold;
	}
	&-operate {
		text-align: right;
		flex: 1;
	}
	&-content {
		background-color: #ffffff;
		border-radius: 10px;
		padding: 20px;
		margin-top: 10px;
	}

	&-footer {
		padding: 20px 0 0;
		border-top: 1px solid #f2f2f2;
		margin-top: 20px;
	}
}
.back {
	font-size: 12px;
	font-weight: normal;
	cursor: pointer;
}
</style>
