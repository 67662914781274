<template>
	<el-row class="com-video">
		<video :id="videoId" class="video" :src="src" controls></video>
		<el-button
			type="text"
			class="com-video__delete"
			icon="el-icon-close"
			@click="onDelete"
		></el-button>
	</el-row>
</template>
<script>
import { base64ToFile } from '@/utils/upload/utils';
import upload from '@/utils/upload/index';
export default {
	name: 'ComVideo',
	props: {
		src: {
			type: String,
		},
		fit: {
			type: String,
			default: 'contain',
		},
		videoId: {
			type: String,
			default: '',
		},
	},
	data() {
		return {};
	},
	watch: {
		src() {
			this.getCoverImage();
		},
	},
	mounted() {
		this.getCoverImage();
	},
	methods: {
		onDelete() {
			this.$emit('delete');
		},
		/** 获取封面图 */
		getCoverImage() {
			if (!this.src || !this.videoId) return;
			const that = this;
			console.log(this.videoId);
			const video = document.getElementById(this.videoId);
			video.currentTime = 1; // 视频快进到第1秒
			video.crossOrigin = 'Anonymous'; // Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
			// 需要监听可以播放事件
			video.addEventListener('canplay', async function () {
				// 创建 canvas 元素
				const canvas = document.createElement('canvas');

				// 设置 canvas 尺寸为视频尺寸
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				// 在 canvas 上绘制视频的第一帧
				canvas
					.getContext('2d')
					.drawImage(video, 0, 0, canvas.width, canvas.height);

				// 获取图片数据，可以用于做一些后续操作
				const imageData = canvas.toDataURL();

				// 转成文件
				const file = await base64ToFile(imageData, 'cover.png]');
				// 上传
				const res = await upload({
					file,
				});
				that.$emit('loaded', {
					coverUrl: res.url,
					videoUrl: that.src,
				});
			});
		},
	},
};
</script>
<style scoped lang="scss">
.com-video {
	width: 320px;
	height: 180px;
	position: relative;
	.video {
		width: 100%;
		height: 100%;
	}

	&__delete {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(50%, -50%);
		width: 16px !important;
		height: 16px !important;
		padding: 0;
		border-radius: 50%;
		background-color: rgba($color: #000000, $alpha: 0.4);
		cursor: pointer;
		color: #fff;
	}
}
</style>
