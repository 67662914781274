import * as ww from '@wecom/jssdk';
import { processEnv } from './env';
let wwLogin = null;

function getLoginParams() {
	const data = {
		development: {
			appid: 'wx59cc12dbc3a0fe4c',
			agentid: '1000050',
			redirect_uri: processEnv.VUE_APP_API_ROOT + '/login',
		},
		test: {
			appid: 'wx59cc12dbc3a0fe4c',
			agentid: '1000050',
			redirect_uri: processEnv.VUE_APP_API_ROOT + '/login',
		},
		uat: {
			appid: 'wx5221b8f4f2c783ab',
			agentid: '1000077',
			redirect_uri: processEnv.VUE_APP_API_ROOT + '/login',
		},
		production: {
			appid: 'wx5221b8f4f2c783ab',
			agentid: '1000076',
			redirect_uri: processEnv.VUE_APP_API_ROOT + '/login',
		},
	};
	return data[processEnv.NODE_ENV || 'test'];
}
/**
 * @description: 初始化企业微信登录组件
 */
export function init() {
	return new Promise((resolve, reject) => {
		// 初始化
		wwLogin = ww.createWWLoginPanel({
			el: '#ww_login',
			params: {
				login_type: ww.WWLoginType.corpApp,
				...getLoginParams(),
				state: 'loginState',
				panel_size: ww.WWLoginPanelSizeType.small,
				redirect_type: ww.WWLoginRedirectType.callback,
			},
			onCheckWeComLogin({ isWeComLogin }) {
				console.log(isWeComLogin);
			},
			onLoginSuccess({ code }) {
				resolve({ code });
			},
			onLoginFail(err) {
				reject(err);
			},
		});
	});
}

/**
 * @description: 销毁企业微信登录组件
 */
export function unmount() {
	wwLogin?.unmount();
	wwLogin = null;
}
