import Vue from 'vue';

const http = Vue.axios;

let apis = {
	unreadLetter(data) {
		return http('admin/message-send/unread', {
			method: 'GET',
			data,
		});
	},
	detailLetter(data) {
		return http('admin/message-send/see', {
			method: 'POST',
			data,
		});
	},
	addLetter(data) {
		return http('admin/message-send/add', {
			method: 'POST',
			data,
		});
	},
};

export default apis;
