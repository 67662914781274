<template>
	<div
		class="login"
		:style="{ backgroundImage: 'url(' + webSite.login_background_image + ')' }"
	>
		<div class="login-box">
			<div class="login-title">
				<div class="back-btn" @click="goLogin">
					<i class="el-icon-caret-left m-r-5"></i>返回登录
				</div>
			</div>
			<div class="login-content">
				<div class="title">密码找回</div>
				<div class="content-box">
					<div class="lc-item">
						<span>账号</span>
						<el-input
							v-model="info.account"
							placeholder="请输入账号"
							:class="[infoFlag.account ? 'input-red' : '']"
						></el-input>
					</div>
					<div class="lc-item">
						<span>验证码</span>
						<div class="ver-code">
							<div class="df">
								<el-input
									v-model="info.captcha"
									placeholder="请输入验证码"
									style="width: 64%; margin-right: 5%"
									:class="[infoFlag.captcha ? 'input-red' : '']"
								></el-input>
								<el-button
									plain
									@click="getPhoneCode"
									:disabled="phoneCode.disabled"
									class="phone-code"
									:style="{
										color: phoneCode.disabled ? '#ccc' : '#708DF5',
									}"
									>{{ phoneCode.text }}</el-button
								>
							</div>
							<span class="forget-pwd">{{ verCodeSendText }}</span>
						</div>
					</div>
					<div class="lc-item">
						<span>新密码</span>
						<div class="ver-code">
							<el-input
								@keyup.enter.native="handleNewPwd"
								v-model="info.password"
								placeholder="请设置您的新密码"
								type="password"
								autocomplete="new-password"
								:class="[infoFlag.password ? 'input-red' : '']"
							></el-input>
							<span class="forget-pwd">8-16位英数混合字符</span>
						</div>
					</div>
					<div class="lc-item">
						<span>密码确认</span>
						<el-input
							@keyup.enter.native="handleNewPwd"
							v-model="info.confirmPassword"
							placeholder="请再次确认您的新密码"
							type="password"
							autocomplete="new-password"
							:class="[infoFlag.confirmPassword ? 'input-red' : '']"
						></el-input>
					</div>
					<div class="error-tip" v-for="item in errorTip" :key="item">
						{{ item }}
					</div>
					<el-button type="primary" class="login-button" @click="handleNewPwd"
						>完成</el-button
					>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Api from '@/apis/public/common';
import login_background_image from '@/assets/login-bac.jpg';
import system_logo from '@/assets/vchangyi.png';
export default {
	name: 'Login',
	data() {
		return {
			// 校验信息
			info: {
				account: '',
				password: '',
				confirmPassword: '',
				captcha: '',
			},
			// 校验信息状态
			infoFlag: {
				account: false,
				password: false,
				confirmPassword: false,
				captcha: false,
			},
			errorTip: [], // 错误提示信息
			// 手机验证码
			phoneCode: {
				text: '发送验证码',
				disabled: false,
			},
			verCodeSendText: '', // 成功发送验证码后，显示的手机号提示
			webSite: {
				site_name: '后台管理系统',
				login_background_image,
				system_logo,
			},
		};
	},
	mounted() {
		let response = JSON.parse(localStorage.getItem('websit'));
		this.webSite.site_name = response.site_name
			? response.site_name
			: this.webSite.site_name;
		this.webSite.login_background_image = response.login_background_image
			? response.login_background_image
			: this.webSite.login_background_image;
		this.webSite.system_logo = response.system_logo
			? response.system_logo
			: this.webSite.system_logo;
	},
	methods: {
		// 校验
		checkInfo() {
			this.errorTip = [];
			for (const key in this.infoFlag) {
				this.infoFlag[key] = false;
			}
			if (!this.info.account) {
				this.errorTip.push('请输入账号');
				this.infoFlag.account = true;
				return false;
			}
			if (!this.info.captcha) {
				this.errorTip.push('请输入验证码');
				this.infoFlag.captcha = true;
				return false;
			}
			if (!this.info.password) {
				this.errorTip.push('请输入新密码');
				this.infoFlag.password = true;
				return false;
			}
			if (
				8 > this.info.password.length ||
				this.info.password.length > 16 ||
				!/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/.test(this.info.password)
			) {
				this.errorTip.push('请输入8-16位英数混合字符的密码');
				this.infoFlag.password = true;
				return false;
			}
			if (this.info.confirmPassword !== this.info.password) {
				this.errorTip.push('两次密码输入不一致，请检查后重新输入');
				this.infoFlag.confirmPassword = true;
				return false;
			}
			return true;
		},
		// 提交新密码
		async handleNewPwd() {
			if (!this.checkInfo()) return;
			try {
				let { account, password, confirmPassword, captcha } = this.info;
				const response = await Api.setPassword({
					account,
					password,
					confirm_password: confirmPassword,
					code: captcha,
				});
				if (response.status === 'success' && response.httpCode === 200) {
					this.$message.success('密码修改成功');
					// 页面跳转
					this.$router.push({
						path: 'login',
					});
				}
			} catch (error) {
				console.log(error);
			}
		},
		// 获取验证码
		async sendSetPasswordCode() {
			let response = await Api.sendSetPasswordCode({
				account: this.info.account,
			});
			if (response.status === 'success' && response.httpCode === 200) {
				this.$message.success('验证码发送成功');
				this.verCodeSendText = `验证码已发送至${response.data.email}邮箱`;
			}
		},
		// 获取手机验证码倒计时
		async getPhoneCode() {
			this.errorTip = [];
			if (this.phoneCode.disabled) return;
			if (!this.info.account) {
				this.errorTip.push('请输入账号');
				this.infoFlag.account = true;
				return false;
			}
			try {
				await this.sendSetPasswordCode();
				let time = 60;
				this.phoneCode.disabled = true;
				this.phoneCode.text = `${time}s`;
				let timer = setInterval(() => {
					time--;
					this.phoneCode.text = `${time}s`;
					if (time === 0) {
						clearInterval(timer);
						this.phoneCode.disabled = false;
						this.phoneCode.text = '发送验证码';
					}
				}, 1000);
			} catch (error) {
				console.log(error);
			}
		},
		// 返回登录
		goLogin() {
			this.$router.replace('/login');
		},
	},
};
</script>

<style lang="scss" scoped>
$font-color: #292a2b;
$font-18: 2.2vh;
$font-26: 3.6vh;
::v-deep .el-input--small .el-input__inner {
	height: 40px;
	line-height: 40px;
}
::v-deep .el-input__inner:focus {
	box-shadow: 0 2px 5px rgba(91, 91, 91, 0.1);
}
.login {
	width: 100%;
	height: 100vh;
	background: url($login-bg);
	background-repeat: repeat;
	background-size: contain;
	display: flex;
	justify-content: flex-end;
}
.login-box {
	position: relative;
	width: 37.5%;
	min-width: 476px;
	height: 100%;
	background-color: #ffffff;
	border-radius: 35px 0 0 0;
	.login-title {
		position: absolute;
		top: 20px;
		right: 20px;
		z-index: 21;
		.back-btn {
			width: 18vh;
			height: 7vh;
			font-size: $font-18;
			min-width: 84px;
			min-height: 34px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 35px;
			color: #696969;
			background-color: rgba(216, 216, 216, 0.2);
			cursor: pointer;
		}
	}
	.login-content {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 20;
		width: 80%;
		margin-left: 12.2%;
		padding: 12vh 4vh 0;
		height: 60vh;
		border-radius: 4px;
		box-sizing: border-box;
		.title {
			font-size: $font-26;
			font-weight: 600;
			margin-bottom: 8vh;
		}
		.content-box {
			min-height: 340px;
			color: $font-color;
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			.lc-item {
				font-size: $font-18;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin-bottom: 4vh;
				box-sizing: content-box;
				span {
					margin-bottom: 1.4vh;
				}
				.ver-code {
					display: flex;
					flex-direction: column;
					.df {
						display: flex;
					}
					::v-deep .el-button {
						color: $color-main;
					}
					::v-deep .el-button--small {
						padding: 0;
					}
					.phone-code {
						width: 31%;
						overflow: hidden;
						color: rgb(112, 141, 245);
						text-align: center;
						height: 40px;
						line-height: 24px;
						font-weight: normal;
					}
					.forget-pwd {
						display: block;
						color: $color-main;
						font-size: 10px;
						margin-top: 5px;
					}
				}
				.input-red ::v-deep .el-input__inner {
					border-color: $color-danger;
				}
			}
			.error-tip {
				font-size: 10px;
				color: $color-danger;
			}
			.login-button {
				height: 40px;
				margin-top: 3%;
				letter-spacing: 6px;
				font-weight: 500;
				box-shadow: 0 11px 21px #d6e1ff;
			}
		}
	}
}
</style>
