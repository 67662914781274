<template>
	<div class="no-page">
		<img
			class="no-page-img"
			src="https://oss-crs.vchangyi.com/wechat/no-contacts-icon.png"
			alt=""
		/>
		<div class="no-page-title">该功能不可用，请先授权企业微信通讯录应用</div>
		<div class="operation-box"></div>
		<el-button size="small" type="primary" @click="goAuthorization"
			>立即授权</el-button
		>
	</div>
</template>
<script>
export default {
	name: 'ErrorNoContacts',
	data() {
		return {};
	},
	methods: {
		// 去授权通讯录
		goAuthorization() {},
	},
	mounted() {},
};
</script>
<style lang="scss" scoped>
.no-page {
	background: #f5f7fa;
	position: relative;
	height: 100%;
	display: flex;
	align-content: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;

	.no-page-img {
		display: block;
		height: 275px;
		width: 360px;
		left: 0;
		right: 0;
		margin: auto;
		margin-bottom: 0;
		margin-top: 0;
	}

	.no-page-title {
		color: #606266;
		font-size: 20px;
		display: block;
	}

	.operation-box {
		font-size: 0;
		height: 14px;
		line-height: 1;
		display: inline-block;
		margin-top: 20px;
	}
}
</style>
