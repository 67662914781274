<template>
	<!-- 顶部栏主要为顶部面包屑/下载中心/站内信/用户中心 -->
	<el-header :class="{ black: mode }">
		<!-- 面包屑部分 -->
		<el-row class="center">
			<template v-if="mode === 'vertical'">
				<el-button
					class="btn m-r-10"
					type="text"
					:icon="!collapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
					@click="setCollapse(!collapse)"
				></el-button>
				<layout-bread></layout-bread>
			</template>
			<layout-menu v-if="mode === 'thr'"></layout-menu>
		</el-row>
		<!-- 下载中心/站内信/用户中心 -->
		<el-row class="user">
			<!-- TODO 站内信 -->
			<!-- 账号信息 -->
			<layout-user @selectEnterprise="selectEnterprise"></layout-user>
		</el-row>
	</el-header>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import LayoutBread from '../layout-bread/index.vue';
import LayoutUser from '../layout-user/index.vue';
import LayoutMenu from '../layout-menu/index.vue';
import Api from '@/apis/system/letter';
export default {
	name: 'LayoutHeader',
	components: {
		LayoutBread,
		LayoutUser,
		LayoutMenu,
	},
	data() {
		return {
			scroll: false,
			downSee: false,
			messageSee: false,
			list: [{}, {}, {}],
		};
	},
	computed: {
		...mapGetters('SETTING', {
			mode: 'getMode',
			collapse: 'getCollapse',
		}),
		...mapGetters('BREAD-CRUMB', {
			BREADCRUMB: 'getBreadCrumb',
		}),
	},
	methods: {
		...mapActions('SETTING', ['setCollapse']),
		async letterList() {
			try {
				const res = await Api.unreadLetter();
				this.list = res.data.list;
			} catch (err) {
				console.log('letterList', err);
			}
		},
		// 跳转下载中心
		goDownload() {
			// this.messageSee = false;
			// this.downSee = !this.downSee;
			this.$router.push({
				name: 'myapp_download_list',
			});
		},
		// 查看站内信
		goMessage() {
			this.downSee = false;
			this.messageSee = !this.messageSee;
		},
		// 顶部面包屑跳转页面
		goPage(item) {
			this.$router.push(item.path);
		},
		// 修改密码待开发
		selectEnterprise() {},
	},
	mounted() {
		this.letterList();
	},
};
</script>
<style lang="scss" scoped>
.el-header {
	color: #000;
	background-color: #fff;
	display: flex;
	align-items: center;

	&.black {
		color: #fff;
		background-color: var(--side-bar-bg);
	}

	.center {
		flex: 1;
		height: 100%;
		display: flex;
		align-items: center;
	}
	.btn {
		color: #000;
		font-size: 20px !important;
	}
}
</style>
