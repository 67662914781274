//  单一状态树
const state = {
	PERMISSION: [],
	ROLES: [],
	STATUS: 'router',
};

// getters 暴露出去的获取 state 的计算属性
const getters = {
	getPermission: state => {
		let _permission = state.PERMISSION;
		if (!state.PERMISSION || state.PERMISSION.length === 0) {
			const list = localStorage.getItem('PERMISSION') || '[]';
			_permission = JSON.parse(list);
		}
		return _permission;
	},
	getRoleList: state => {
		return state.ROLES;
	},
	getStatus: state => {
		return state.STATUS;
	},
	getRoles: state => {
		return state.ROLES;
	},
};

// mutations 私有化方法，只能被 actions 调用， 可以直接变更状态
const mutations = {
	SET_PERMISSION: (state, data) => {
		localStorage.setItem('PERMISSION', JSON.stringify(data));
		state.PERMISSION = data;
	},
	SET_ROLES: (state, data) => {
		localStorage.setItem('ROLES', JSON.stringify(data));
		state.ROLES = data;
	},
	SET_STATUS: (state, data) => {
		state.STATUS = data;
	},
};

// actions 暴露到外部的方法 不直接变更状态
const actions = {
	setPermission: ({ commit }, data) => {
		commit('SET_PERMISSION', data);
	},
	setRoles: ({ commit }, data) => {
		commit('SET_ROLES', data);
	},
	setStatus: ({ commit }, data) => {
		commit('SET_STATUS', data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
