<template>
	<el-aside width="auto">
		<el-row v-if="!collapse" class="logo">管理系统</el-row>
		<!-- 一级菜单栏 -->
		<transition name="fade-1" mode="out-in">
			<el-menu
				:default-active="getDefaultActive($route)"
				:key="getHeaderActive($route)"
				@select="onSelect"
				:collapse="collapse"
			>
				<template v-for="(item, index) in getMenuList($route)">
					<el-submenu
						v-if="item.children && item.children.length"
						:key="index"
						:index="item.key"
					>
						<template #title>
							<!-- <com-icon class="m-r-5" :icon-class="'account'"></com-icon> -->
							<span>{{ item.name }}</span>
						</template>

						<template v-for="(subitem, index) in item.children">
							<el-sub-menu
								v-if="subitem.children && subitem.children.length"
								:key="index"
								:index="subitem.key"
							>
								<template #title>
									<span>{{ subitem.name }}</span>
								</template>

								<el-menu-item
									v-for="(last, i) in subitem.children"
									:key="i"
									:index="last.key"
								>
									<span>{{ last.name }}</span>
								</el-menu-item>
							</el-sub-menu>

							<el-menu-item v-else :key="index" :index="subitem.key">
								<span>{{ subitem.name }}</span>
							</el-menu-item>
						</template>
					</el-submenu>
					<el-menu-item v-else :key="index" :index="item.key">
						<!-- <com-icon class="m-r-5" :icon-class="'account'"></com-icon> -->
						<span slot="title">{{ item.name }}</span>
					</el-menu-item>
				</template>
			</el-menu>
		</transition>
	</el-aside>
</template>
<script>
import { mapGetters } from 'vuex';
import Permission from '@/mixins/Permission';
import { IMenuType } from '@/utils/login/permission';
import { findTreeNode } from '@/utils/tree';

export default {
	name: 'LayoutAside',
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			list: [],
			active: '',
		};
	},
	computed: {
		...mapGetters('SETTING', {
			mode: 'getMode',
			collapse: 'getCollapse',
		}),
		...mapGetters('MENU', {
			MENU: 'getMenu',
			ROOT: 'getRoot',
			LEAF: 'getLeaf',
			viewList: 'getViewList',
			menuList: 'getMenuList',
			getDefaultActive: 'getDefaultActive',
		}),
		getHeaderActive() {
			return route => {
				if (this.mode === 'thr') {
					return this.menuList.find(item => route.name.indexOf(item.key) !== -1)
						.key;
				} else {
					return '';
				}
			};
		},
		getDefaultActive() {
			return route => {
				const menuList = this.getMenuList(route);
				// 1、直接获取满足条件的侧边栏节点
				if (route.meta.type == IMenuType.MENU_VIEW) {
					return findTreeNode([...menuList], item => {
						return route.name === item.key;
					}).key;
				}
				// 2、获取当前节点的父节点
				if (route.meta.type == IMenuType.VIEW) {
					return findTreeNode([...menuList], item => item.id == route.meta.pid)
						.key;
				}
			};
		},
		/** 获取侧边栏菜单 */
		getMenuList() {
			const { menuList, mode } = this;
			return route => {
				if (mode === 'thr') {
					const key = this.getHeaderActive(route);
					return menuList.find(item => item.key === key).children;
				} else {
					return menuList;
				}
			};
		},
	},
	mixins: [Permission],
	mounted() {},
	methods: {
		onSelect(key) {
			const current = this.viewList.find(
				item =>
					item.key.indexOf(key) !== -1 &&
					(item.type == IMenuType.MENU_VIEW || item.type == IMenuType.VIEW),
			);
			console.log('-----onSelect-----\n', current);
			if (current) {
				this.$router.push({
					name: current.key,
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.el-aside {
	background-color: var(--side-bar-bg);
}

.el-menu {
	// width: 180px;
}

.el-menu {
	border-right: solid 0px;
}

.el-menu-item {
	min-width: 100px;
}
.el-menu-item.is-active {
	color: var(--active-text-color);
	background: var(--active-menu-bg);
}

.logo {
	height: 60px;
	width: 160px;
	text-align: center;
	line-height: 60px;
	font-size: 18px;
	font-weight: bold;
	padding-right: 20px;
	box-sizing: border-box;
	color: #fff;
}
</style>
