// 生成一个上下文模块。它包含对该目录中所有模块的引用，对于与正则表达式匹配的请求，可能需要该引用。
// 上下文模块包含一个映射，该映射将请求转换为模块ID。
const files = require.context('./', true, /\.vue$/);

/**
 * 全局注册:在main.js中引入,并使用Vue.use()注册
 */
export default {
	install: Vue => {
		files.keys().forEach(path => {
			// 获取数据
			const value = files(path).default;
			// 获取名称, 注意使用name属性作组件的名称
			const key = value.name;
			// 组件
			Vue.component(key, value);
		});
	},
};
