import { upload as OSSUpload } from './oss';

/**
 * 上传
 * @param {object} config
 * @param {object} config.file 需要上传的文件对象
 * @param {string} config.path 指定上传的服务器目录
 * @param {boolean} config.rename 是否重命名
 * @param {string} type 上传方式
 * @returns
 */
export default async function (config) {
	try {
		return OSSUpload(config);
	} catch (error) {
		throw new Error('上传失败');
	}
}
