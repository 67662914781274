<template>
	<div
		class="login"
		:style="{ backgroundImage: 'url(' + webSite.login_background_image + ')' }"
	>
		<div v-if="activeSuccess" class="login-box">
			<div class="login-content">
				<div class="title">
					欢迎来到{{ title }}
					<p>请在48小时内设置您的密码，完成激活。</p>
				</div>
				<div class="content-box">
					<div class="lc-item">
						<span>账号</span>
						<el-input
							v-model="info.account"
							placeholder="请输入账号"
							disabled
							:class="[infoFlag.account ? 'input-red' : '']"
						></el-input>
					</div>
					<div class="lc-item">
						<span>密码</span>
						<div class="ver-code">
							<el-input
								@keyup.enter.native="handleNewPwd"
								v-model="info.password"
								placeholder="请设置您的新密码"
								type="password"
								:disabled="activeFailed"
								:class="[infoFlag.password ? 'input-red' : '']"
							></el-input>
							<span class="forget-pwd">8-16位英数混合字符</span>
						</div>
					</div>
					<div class="lc-item">
						<span>密码确认</span>
						<el-input
							@keyup.enter.native="handleNewPwd"
							v-model="info.confirmPassword"
							placeholder="请再次确认您的新密码"
							type="password"
							:disabled="activeFailed"
							:class="[infoFlag.confirmPassword ? 'input-red' : '']"
						></el-input>
					</div>
					<div class="error-tip" v-for="item in errorTip" :key="item">
						{{ item }}
					</div>
					<el-button
						type="primary"
						:disabled="activeFailed"
						class="login-button"
						@click="handleNewPwd"
						>完成</el-button
					>
				</div>
			</div>
		</div>
		<div v-else class="login-box">
			<div class="login-content">
				<div style="margin-top: 12vh">
					<img src="@/assets/login/right.png" alt="对" width="46px" />
					<div class="font-26 m-t-30"><b>恭喜您，激活成功！</b></div>
				</div>
				<el-button type="primary" class="to-login-button" @click="goLogin"
					>前往登录</el-button
				>
			</div>
		</div>
	</div>
</template>
<script>
import Api from '@/apis/public/common';
import setting from '@/setting';
import login_background_image from '@/assets/login-bac.jpg';
import system_logo from '@/assets/vchangyi.png';
export default {
	name: 'Login',
	data() {
		return {
			title: setting.title,
			// 校验信息
			info: {
				account: '',
				password: '',
				confirmPassword: '',
			},
			webSite: {
				site_name: '后台管理系统',
				login_background_image,
				system_logo,
			},
			// 校验信息状态
			infoFlag: {
				account: false,
				password: false,
				confirmPassword: false,
			},
			errorTip: [], // 错误提示信息
			activeSuccess: true, // 是否激活
			activeFailed: true, // 激活链接token是否已失效
		};
	},
	mounted() {
		this.checkResetPasswordLink();
		let response = JSON.parse(localStorage.getItem('websit'));
		this.webSite.site_name = response.site_name
			? response.site_name
			: this.webSite.site_name;
		this.webSite.login_background_image = response.login_background_image
			? response.login_background_image
			: this.webSite.login_background_image;
		this.webSite.system_logo = response.system_logo
			? response.system_logo
			: this.webSite.system_logo;
	},
	methods: {
		// 校验
		checkInfo() {
			this.errorTip = [];
			for (const key in this.infoFlag) {
				this.infoFlag[key] = false;
			}
			if (!this.info.account) {
				this.errorTip.push('请输入账号');
				this.infoFlag.account = true;
				return false;
			}
			if (!this.info.password) {
				this.errorTip.push('请输入新密码');
				this.infoFlag.password = true;
				return false;
			}
			if (
				8 > this.info.password.length ||
				this.info.password.length > 16 ||
				!/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/.test(this.info.password)
			) {
				this.errorTip.push('请输入8-16位英数混合字符的密码');
				return false;
			}
			if (this.info.confirmPassword !== this.info.password) {
				this.errorTip.push('两次密码输入不一致，请检查后重新输入');
				this.infoFlag.confirmPassword = true;
				return false;
			}
			return true;
		},
		// 提交新密码
		async handleNewPwd() {
			if (!this.checkInfo()) return;
			try {
				let { account, password, confirmPassword } = this.info;
				const res = await Api.setInitialPassword({
					account,
					password,
					confirm_password: confirmPassword,
				});
				if (res.status === 'success' && res.httpCode === 200) {
					this.$message.success('账号激活成功');
					// 显示激活成功
					this.activeSuccess = false;
				}
			} catch (error) {
				console.log(error);
			}
		},
		// 校验重置初始密码链接地址是否有效
		async checkResetPasswordLink() {
			try {
				this.info.account = this.getUrlParams('account');
				await Api.checkResetPasswordLink({
					account: this.getUrlParams('account'),
					token: this.getUrlParams('token'),
				});
				this.activeFailed = false;
			} catch (error) {
				if (error.data.status === 'error' && error.data.statusCode === -1) {
					this.$message.error('激活链接已过期！');
				}
			}
		},
		// 前往登录
		goLogin() {
			this.$router.push('/login');
		},
		// 获取地址栏的参数信息
		getUrlParams(name) {
			var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
			var r = window.location.search.substr(1).match(reg);
			if (r != null) return unescape(r[2]);
			return null;
		},
	},
};
</script>

<style lang="scss" scoped>
$font-color: #292a2b;
$font-18: 2.2vh;
$font-26: 3.2vh;
::v-deep .el-input--small .el-input__inner {
	height: 40px;
	line-height: 40px;
}
::v-deep .el-input__inner:focus {
	box-shadow: 0 2px 5px rgba(91, 91, 91, 0.1);
}
.login {
	width: 100%;
	height: 100vh;
	background: url($login-bg);
	background-repeat: repeat;
	background-size: contain;
	display: flex;
	justify-content: flex-end;
}
.login-box {
	position: relative;
	width: 37.5%;
	min-width: 476px;
	height: 100%;
	background-color: #ffffff;
	border-radius: 35px 0 0 0;
	.login-content {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 20;
		width: 80%;
		margin-left: 12.2%;
		padding: 16vh 4vh 0;
		height: 60vh;
		border-radius: 4px;
		box-sizing: border-box;
		.title {
			font-size: $font-26;
			margin-bottom: 8vh;
			p {
				line-height: $font-26;
				margin-top: 10px;
				font-weight: bold;
			}
		}
		.content-box {
			min-height: 340px;
			color: $font-color;
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			.lc-item {
				font-size: $font-18;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin-bottom: 4vh;
				box-sizing: content-box;
				span {
					margin-bottom: 1.4vh;
				}
				.ver-code {
					display: flex;
					flex-direction: column;
					::v-deep .el-button {
						color: $color-main;
					}
					.forget-pwd {
						display: block;
						color: $color-main;
						font-size: 10px;
						cursor: auto;
						margin-top: 5px;
					}
				}
				.input-red ::v-deep .el-input__inner {
					border-color: $color-danger;
				}
			}
			.error-tip {
				font-size: 10px;
				color: $color-danger;
			}
			.login-button {
				height: 40px;
				margin-top: 3%;
				font-weight: 500;
				letter-spacing: 6px;
				box-shadow: 0 11px 21px #d6e1ff;
			}
		}
	}
	.to-login-button {
		width: 100%;
		height: 40px;
		font-weight: 500;
		margin-top: 20vh;
		letter-spacing: 6px;
		box-shadow: 0 11px 21px #d6e1ff;
	}
}
</style>
