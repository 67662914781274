<template>
	<div class="app" :style="style">
		<router-view></router-view>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters('SETTING', {
			style: 'getThemeStyle',
		}),
	},
};
</script>
<style lang="scss"></style>
