<template>
	<div class="app">
		<el-container v-if="mode === 'vertical'">
			<!-- 侧边栏菜单部分 -->
			<layout-aside></layout-aside>
			<el-container direction="vertical">
				<!-- 头部栏 -->
				<layout-header></layout-header>
				<!-- 主容器 -->
				<layout-main></layout-main>
			</el-container>
		</el-container>
		<el-container v-if="mode === 'thr'">
			<!-- 侧边栏菜单部分 -->
			<layout-aside></layout-aside>
			<el-container direction="vertical">
				<!-- 头部栏 -->
				<layout-header></layout-header>
				<!-- 主容器 -->
				<layout-main></layout-main>
			</el-container>
		</el-container>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import LayoutAside from './layout-aside/index.vue';
import LayoutHeader from './layout-header/index.vue';
import LayoutMain from './layout-main/index.vue';
export default {
	name: 'layout',
	components: { LayoutAside, LayoutHeader, LayoutMain },
	data() {
		return {
			scroll: false,
		};
	},
	computed: {
		...mapGetters('SETTING', {
			mode: 'getMode',
		}),
	},
	mounted() {},
};
</script>
<style lang="scss" scoped>
.el-container {
	min-width: 980px;
	background-color: #f7f7f7;
	overflow: hidden;

	&.is-vertical {
		height: 100vh;
	}
}
</style>
