<template>
	<div
		class="login"
		:style="{ backgroundImage: 'url(' + webSite.login_background_image + ')' }"
	>
		<div class="login-box">
			<div class="title">{{ webSite.site_name }}</div>
			<el-input
				v-model.trim="info.secret"
				maxlength="11"
				placeholder="请输入动态码"
			></el-input>
			<el-button @click="submit">提 交</el-button>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import Api from '@/apis/public/common';
import Permission from '@/mixins/Permission';
import setting from '@/setting';
import login_background_image from '@/assets/login-bac.jpg';
import system_logo from '@/assets/vchangyi.png';
export default {
	name: 'LoginMiddle',
	data() {
		return {
			title: setting.title,
			info: {
				secret: '',
			},
			webSite: {
				site_name: '后台管理系统',
				login_background_image,
				system_logo,
			},
		};
	},
	mixins: [Permission],
	mounted() {
		let response = JSON.parse(localStorage.getItem('websit'));
		this.webSite.site_name = response.site_name
			? response.site_name
			: this.webSite.site_name;
		this.webSite.login_background_image = response.login_background_image
			? response.login_background_image
			: this.webSite.login_background_image;
		this.webSite.system_logo = response.system_logo
			? response.system_logo
			: this.webSite.system_logo;
	},
	computed: {
		...mapGetters('MENU', {
			MENN: 'getMenu',
		}),
	},
	methods: {
		// 登录
		async submit() {
			try {
				let res = await Api.loginAuth(this.info);
				if (res.data.verify_status) {
					await this.permissionUpdate();
					this.$message.success('登录成功');
					// 页面跳转
					this.$router.push({
						name: await this.reRoute(this.MENN),
					});
				} else {
					this.info.secret = '';
					this.$message.error('动态码验证失败');
				}
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .input-with-select .el-input-group__prepend {
	background-color: #fff;
}

.login {
	width: 100%;
	height: 100vh;
	background: url($login-bg);
	background-repeat: repeat;
	background-size: cover;
}

.login-box {
	position: fixed;
	left: 50%;
	top: 50%;
	width: 460px;
	height: 306px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
	margin-top: -168px;
	margin-left: -230px;
	padding: 40px 60px;

	.title {
		font-size: 16px;
		color: #1989fa;
	}

	.el-input {
		margin-top: 20px;
	}

	.el-button {
		width: 100%;
		color: #fff;
		margin-top: 30px;
		background-color: #282a32;
		border-color: #282a32;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
	}

	.el-button:hover,
	.el-button:focus {
		color: #fff;
		background-color: #282a32;
		border-color: #282a32;
	}

	input {
		text-indent: 0;
		padding-left: 0 !important;
		border-radius: 0px;
		border-left: none;
		border-right: none !important;
		border-top: none;
	}
}
</style>
