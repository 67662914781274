// 基础路由
import Login from '@/views/base/common/login';
import LoginQrcode from '@/views/base/common/login-qrcode';
import ForgetPassword from '@/views/base/common/forget-password';
import ActivateAccount from '@/views/base/common/activate-account';
import LoginMiddle from '@/views/base/common/login-middle';
import NoPage from '@/views/base/error/no-page';
import NoContact from '@/views/base/error/no-contact';
import NoPower from '@/views/base/error/no-power';
import NoApp from '@/views/base/error/no-crs';
import Layout from '@/layout/layout';

export default [
	{
		path: '/login',
		name: 'login',
		component: Login,
	},
	{
		path: '/login-qrcode',
		name: 'login-qrcode',
		component: LoginQrcode,
	},
	{
		path: '/forget-password',
		name: 'forget-password',
		component: ForgetPassword,
	},
	{
		path: '/activate-account',
		name: 'activate-account',
		component: ActivateAccount,
	},
	{
		path: '/login-middle',
		name: 'login-middle',
		component: LoginMiddle,
	},
	{
		path: '/error',
		name: 'error',
		component: Layout,
		children: [
			{
				path: '404',
				name: 'unpage',
				component: NoPage,
			},
			{
				path: '401',
				name: 'uncontact',
				component: NoContact,
			},
			{
				path: '402',
				name: 'unpower',
				component: NoPower,
			},
			{
				path: '403',
				name: 'unapp',
				component: NoApp,
			},
		],
	},
	{
		path: '/',
		redirect: {
			name: 'login',
		},
	},
	{
		path: '/myapp',
		name: 'myapp',
		component: Layout,
	},
	// {
	// 	path: '*',
	// 	redirect: {
	// 		name: 'unpage',
	// 	},
	// },
];
