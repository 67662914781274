import { filterTreeNode, forEachTree } from '../tree';

/** @enum {number} */
export const IMenuType = {
	/** 接口，用来判断接口的使用权限，可以在调用接口的时候判断 */
	API: 0,
	/** 菜单，控制菜单展示，不生成路由列表 */
	MENU: 1,
	/** 视图，控制页面整体内容展示，用于生成路由列表 */
	VIEW: 2,
	/** 按钮，控制页面局部内容展示，不生成路由和菜单 */
	BUTTON: 3,
	/** 菜单视图，既需要当作菜单，又需要生成路由，如果当前菜单下面只有一个视图，可以缩写成菜单视图，菜单即视图, 如果解决了当菜单下面只有一个视图的时候，把视图当做菜单替换原来的菜单 */
	MENU_VIEW: 4,
};
/**
 * 拆分权限
 * @param menu 权限原始数据
 * @returns
 */
export function splitPermissionData(menu) {
	const list = [...menu];
	// 页面数据/视图数据
	const viewList = [];
	// 按钮数据
	const buttonList = [];
	//  接口数据
	const apiList = [];

	forEachTree([...list], item => {
		// 分类
		switch (item.type) {
			case IMenuType.API:
				apiList.push(item);
				break;
			case IMenuType.VIEW:
			case IMenuType.MENU_VIEW:
				viewList.push(item);
				break;
			case IMenuType.BUTTON:
				buttonList.push(item);
				break;

			default:
				break;
		}
	});

	// 菜单数据
	const menuList = filterTreeNode([...list], item => {
		return item.type === IMenuType.MENU || item.type === IMenuType.MENU_VIEW;
	});
	return { menuList, viewList, buttonList, apiList };
}
