<template>
	<svg class="icon-svg" :class="className" aria-hidden="true" v-on="$listeners">
		<use :xlink:href="iconName" />
	</svg>
</template>

<script>
export default {
	name: 'ComIcon',
	props: {
		iconClass: {
			type: String,
			required: true,
		},
		className: {
			type: String,
			default: '',
		},
	},
	computed: {
		iconName() {
			return `#icon-${this.iconClass}`;
		},
	},
};
</script>

<style lang="scss" scoped>
.icon-svg {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
</style>
