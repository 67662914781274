// 阿里云上传方式
import OSS from 'ali-oss';
import Vue from 'vue';
const http = Vue.axios;

import { setFilePath } from './utils.js';
import { processEnv } from '../env.js';

const ossConfig = {
	// accessKeyId: processEnv.VUE_APP_ACCESS_KEY_ID,
	// accessKeySecret: processEnv.VUE_APP_ACCESS_SECRET,
	region: processEnv.VUE_APP_OSS_REGION,
	bucket: processEnv.VUE_APP_OSS_BUCKET,
	timeout: 5 * 60 * 1000,
};

const refreshSTSToken = async () => {
	const res = await http('admin/oss-token', {
		method: 'GET',
		loadingHide: true,
	});
	return {
		accessKeyId: res.data.accessKeyId,
		accessKeySecret: res.data.accessKeySecret,
		stsToken: res.data.securityToken,
	};
};

/**
 * 上传文件
 * @param {object} config
 * @param {object} config.file 需要上传的文件对象
 * @param {string} config.path 指定上传的服务器目录
 * @param {boolean} config.rename 是否重命名
 * @returns
 */
export function upload(config) {
	const { file } = config;
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		ossConfig.accessKeyId = processEnv.VUE_APP_ACCESS_KEY_ID;
		ossConfig.accessKeySecret = processEnv.VUE_APP_ACCESS_SECRET;
		try {
			const auth = await refreshSTSToken();
			ossConfig.stsToken = auth.stsToken;
			ossConfig.accessKeyId = auth.accessKeyId;
			ossConfig.accessKeySecret = auth.accessKeySecret;
			ossConfig.refreshSTSToken = refreshSTSToken;
		} catch (error) {
			reject(error);
		}

		const client = OSS ? new OSS(ossConfig) : null;
		const headers = {
			'x-oss-object-acl': 'public-read',
		};
		try {
			// 文件路径
			const filePath = setFilePath(config);

			const data = await client.put(filePath, file, headers);
			resolve({
				url: data.url,
				result: data,
			});
		} catch (err) {
			reject(err);
		}
	});
}
