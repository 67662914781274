//  单一状态树
const state = {
	BASE_MOD_KEYS: new Set(),
};

// getters 暴露出去的获取 state 的计算属性
const getters = {
	getAppKey: state => {
		return state.BASE_MOD_KEYS;
	},
};

// mutations 私有化方法，只能被 actions 调用， 可以直接变更状态
const mutations = {
	SET_KEYS(state, data) {
		if (typeof data === 'string') {
			state.BASE_MOD_KEYS.add(data);
		} else {
			state.BASE_MOD_KEYS = new Set([...this.BASE_MOD_KEYS, ...data]);
		}
	},
};

// actions 暴露到外部的方法 不直接变更状态
const actions = {
	setKeys({ commit }, data) {
		commit('SET_KEYS', data);
	},
	// 更新domain
	setDomain({ commit }, data) {
		commit('setDomain', data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
