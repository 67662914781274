/*
 * @Author: your name
 * @Date: 2020-10-13 17:46:25
 * @LastEditTime: 2020-11-06 14:29:29
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /crs-admin-base/src/store/modules/customer.ts
 */

//  单一状态树
const state = {
	GROUPS: [],
	GROUPU: [],
	LABELEDIT: null,
	GROUPNAME: '',
};

// getters 暴露出去的获取 state 的计算属性
const getters = {
	getGroupS: state => {
		return state.GROUPS;
	},
	getGroupU: state => {
		return state.GROUPU;
	},
	getGroupName: state => {
		return state.GROUPNAME;
	},
	getLabelEdit: state => {
		return (
			state.LABELEDIT ||
			JSON.parse(
				sessionStorage.getItem('CUSTOMERLABELEDIT') || JSON.stringify({}),
			)
		);
	},
};

// mutations 私有化方法，只能被 actions 调用， 可以直接变更状态
const mutations = {
	SET_GROUP_S: (state, data) => {
		state.GROUPS = data;
	},
	SET_GROUP_U: (state, data) => {
		state.GROUPU = data;
	},
	SET_GROUP_NAME: (state, data) => {
		state.GROUPNAME = data;
	},
	SET_LABELEDIT: (state, data) => {
		sessionStorage.setItem('CUSTOMERLABELEDIT', JSON.stringify(data));
		state.LABELEDIT = data;
	},
};

// actions 暴露到外部的方法 不直接变更状态
const actions = {
	setGroupS: ({ commit }, data) => {
		commit('SET_GROUP_S', data);
	},
	setGroupU: ({ commit }, data) => {
		commit('SET_GROUP_U', data);
	},
	setGroupName: ({ commit }, data) => {
		commit('SET_GROUP_NAME', data);
	},
	setLabelEdit: ({ commit }, data) => {
		commit('SET_LABELEDIT', data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
