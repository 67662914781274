import Vue from 'vue';

const http = Vue.axios;
const apis = {
	/**
	 * @description 获取员工列表
	 * @param {object} data
	 */
	getStaffListApi(data) {
		return http('admin/staff', {
			method: 'GET',
			params: data,
		});
	},
	/**
	 * @description 获取员工详情
	 * @param {string} id id
	 */
	getStaffDetailApi(id) {
		return http(`admin/staff/${id}`, {
			method: 'GET',
		});
	},
	/**
	 * @description 新增员工
	 * @param {object} data 数据
	 */
	addStaffApi(data) {
		return http(`admin/staff`, {
			method: 'POST',
			data: data,
		});
	},
	/**
	 * @description 编辑员工
	 * @param {object} data 数据
	 */
	updateStaffApi(data) {
		return http(`admin/staff`, {
			method: 'PUT',
			data: data,
		});
	},
	/**
	 * @description 删除员工
	 * @param {string} id id
	 */
	deleteStaffApi(id) {
		return http(`admin/staff/${id}`, {
			method: 'DELETE',
		});
	},
	/**
	 * @description 获取部门列表
	 */
	getDepartmentListApi(data) {
		return http(`/admin/department`, {
			method: 'GET',
			params: data,
		});
	},
	/**
	 * @description 获取部门下的员工列表
	 */
	getUserListApi(data) {
		return http(`/admin/department/staff`, {
			method: 'GET',
			params: data,
		});
	},
	/**
	 * @description 员工同步
	 */
	updateUserListApi() {
		return http(`/admin/staff/sync`, {
			method: 'POST',
		});
	},
};

export default apis;
