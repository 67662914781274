<template>
	<div class="upload-box">
		<el-upload
			class="upload-el"
			:disabled="disabled"
			:action="action"
			:headers="headers"
			:accept="accept.join()"
			:show-file-list="false"
			:on-success="onSuccess"
			:before-upload="beforeUpload"
		>
			<img v-if="imageUrl" :src="imageUrl" class="avatar" />
			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
		</el-upload>
	</div>
</template>
<script>
import { processEnv } from '@/utils/env';
import { getTokenInfo } from '@/utils/login/storage';

export default {
	name: 'UploadPic',
	props: {
		disabled: {
			//是否禁用
			type: Boolean,
			default: false,
		},
		accept: {
			type: Array,
			default: () => ['jpeg', 'jpg', 'png'],
		},
		onSuccess: {
			type: Function,
			default: () => {},
		},
		beforeUpload: {
			type: Function,
			default: () => {},
		},
		imageUrl: {
			type: String,
			default: '',
		},
	},
	computed: {
		action() {
			return processEnv.VUE_APP_API_ROOT + 'admin/upload';
		},
		headers() {
			return {
				Authorization: getTokenInfo(),
			};
		},
	},
	data() {
		return {};
	},
};
</script>
<style lang="scss" scoped>
.upload-el {
	.el-upload {
		z-index: 10;
		// border: 1px dashed #d9d9d9;
		// border-radius: 6px;
		position: relative;
		overflow: hidden;
	}
}
.avatar-uploader-icon {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	font-size: 28px;
	color: #8c939d;
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
}
.avatar {
	width: 100px;
	height: 100px;
	display: block;
}
</style>
