const includes = ['VUE_APP_', 'NODE_ENV'];

function isInclude(key) {
	return includes.some(item => key.indexOf(item) !== -1);
}
/**
 * 获取对象的部分属性
 * @param {Object<string, string>} obj
 * @returns {Object<string, string>}
 */
function getProperty(obj) {
	let res = {};
	for (const key in obj) {
		if (Object.hasOwnProperty.call(obj, key)) {
			if (isInclude(key) && obj[key]) {
				res[key] = obj[key];
			}
		}
	}
	return res;
}
/**
 * 获取环境变量
 * @returns {Object<string, string>}
 */
export function getProcessEnv() {
	let data = {};
	if (process.env.NODE_ENV === 'production') {
		data = {
			...data,
			...getProperty(window),
		};
	} else {
		data = {
			...data,
			...getProperty(process.env),
		};
	}

	return data;
}

export const processEnv = getProcessEnv();
export const isDev = processEnv.NODE_ENV === 'dev';
export const isTest = processEnv.NODE_ENV === 'test';
export const isProd = processEnv.NODE_ENV === 'production';
export const isServer = process.env.NODE_ENV === 'development';
export const isBuild = process.env.NODE_ENV === 'production';

console.log('环境变量', process.env);
console.log('环境变量', processEnv);
