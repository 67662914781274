<template>
	<vue-ueditor-wrap v-model="msg" :config="config"></vue-ueditor-wrap>
</template>
<script>
import { processEnv } from '@/utils/env';
import VueUeditorWrap from 'vue-ueditor-wrap';

let BASE_URL = processEnv.VUE_APP_API_ROOT;
export default {
	name: 'ComUeditor',
	components: {
		VueUeditorWrap,
	},
	data() {
		return {
			msg: this.value,
			config: {
				// 编辑器不自动被内容撑高
				autoHeightEnabled: true,
				// 初始容器高度
				initialFrameHeight: 350,
				// 初始容器宽度
				initialFrameWidth: '100%',
				// 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
				//        serverUrl: '/admin/ueditor/upload?action=config',
				// UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
				UEDITOR_HOME_URL: '/UEditor/',
				serverUrl: `${BASE_URL}/admin/ueditor/upload`, // php 路径 zIndex: 900,
				allowDivTransToP: false,
				// iframeCssUrl: "lib/css/ueditor-ceontent.css", // 改为手动引入
				enableAutoSave: false, // 自动保存
				saveInterval: 100000000, // 修复自动保存取消无效
				autoSyncData: false,
				wordCount: false, // 字数统计
				autoFloatEnabled: false, // 自动浮在顶部
				insertorderedlist: {
					// 有序列表
					//系统自带
					decimal: '', //'1,2,3...'
					'lower-alpha': '', // 'a,b,c...'
					'lower-roman': '', //'i,ii,iii...'
					'upper-alpha': '', //'A,B,C'
					'upper-roman': '', //'I,II,III...'
				},
				insertunorderedlist: {
					// 有序列表
					circle: '', // '○ 小圆圈'
					disc: '', // '● 小圆点'
					square: '', //'■ 小方块'
				},
				fontsize: [12, 14, 15, 16, 18, 20, 22, 24],
				toolbars: [
					[
						'source',
						'|',
						'undo',
						'redo',
						'|',
						'bold',
						'italic',
						'underline',
						'|',
						'forecolor',
						'backcolor',
						'insertorderedlist',
						'insertunorderedlist',
						'lineheight',
						'|',
						'fontfamily',
						'fontsize',
						'justifyleft',
						'justifyright',
						'justifycenter',
						'justifyjustify',
						'|',
						'imagenone',
						'imageleft',
						'imageright',
						'imagecenter',
						'|',
						'simpleupload',
					],
				],
			},
		};
	},
	model: {
		prop: 'value',
		event: 'change',
	},
	props: {
		value: { type: String },
	},
	mounted() {},
	methods: {},
	watch: {
		msg(newMsg) {
			this.$emit('change', newMsg);
		},
		value(newMsg) {
			this.msg = newMsg;
		},
	},
};
</script>

<style lang="scss" scoped></style>
