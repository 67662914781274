import { formatDate } from '@/utils/format';
import { processEnv } from '../env';
/**
 * 获取文件目录
 *
 * @returns 项目名称_端/环境/yyyyMMdd
 */
export const setFolderName = () => {
	const name = processEnv.VUE_APP_PROJECT_NAME + '_pc';
	const env = processEnv.NODE_ENV;
	return [name, env, formatDate(Date.now(), `yyyyMMdd`)].join('/');
};

/**
 * 重置
 * @param {string} fileName 文件名称
 * @param {boolean} rename 是否重命名
 * @returns
 */
export const resetFileName = (fileName, rename = true) => {
	if (rename) {
		return (
			'' +
			Date.now() +
			~~(Math.random() * 1000) +
			fileName.substr(fileName.lastIndexOf('.'))
		);
	} else {
		return fileName;
	}
};

/**
 * 设置服务器文件目录
 * @param {object} param
 * @param {object} param.file 需要上传的文件对象
 * @param {string} param.path 指定上传的服务器目录
 * @param {boolean} param.rename 是否重命名
 */
export const setFilePath = ({ file, path, rename = true }) => {
	const fileName = resetFileName(file.name, rename);
	const folderName = path ? path : setFolderName();

	return `${folderName}/${fileName}`;
};

export function base64ToFile(base64string, filename = 'file') {
	// 获取文件类型
	const match = base64string.match(/data:([a-zA-Z]+\/[a-zA-Z]+)/);
	const type = match ? match[1] : '';
	// 获取 base64 编码的文件内容
	const content = atob(base64string.split(';base64,')[1]);
	// 将内容转换为一个 ArrayBuffer
	const buffer = new ArrayBuffer(content.length);
	const view = new Uint8Array(buffer);
	for (let i = 0; i < content.length; i++) {
		view[i] = content.charCodeAt(i);
	}
	// 创建一个 Blob 对象，并将其保存到文件
	const blob = new Blob([buffer], { type });
	const file = new File([blob], filename, { type });
	return new Promise((resolve, reject) => {
		if (file) {
			resolve(file);
		} else {
			reject(Error('Failed to create file.'));
		}
	});
}
