import MenuModule from '@/store/modules/menu';
//  单一状态树
const state = {
	BREADCRUMB: [],
};

// getters 暴露出去的获取 state 的计算属性
const getters = {
	getBreadCrumb: state => {
		return state.BREADCRUMB;
	},
	/*
	 *  面包屑展示规则
	 *  1. 一条数据：直接展示
	 *  2. 两条数据：只展示第二条
	 *  3. 三条以上: 第三条开始逐级展示
	 *
	 *  type : 1 菜单
	 *  type : 2 视图
	 *  type : 3 按钮
	 *  type : 4 菜单+视图
	 *
	 *  1. 一条数据：直接展示：一级
	 *  2. 两条数据：
	 *      1. 当最后一条数据type===4: 展示 二级
	 *      2. 当最后一条数据type===2: 展示 一级+二级（非第一条数据）
	 *  3. 三条数据：
	 *      1. 当最后一条数据type===4: 展示 三级
	 *      2. 当最后一条数据type===2: 展示 二级+三级（非第一条数据）
	 *  4. 四条数据：
	 *      1. 展示 三级+四级（非第一条数据）
	 *
	 * 最后一个子节点type===4 展示：当前级别
	 * 最后一个子节点type===2 展示：父级+子级（非第一条数据）
	 *
	 */
	getBCShowRule: state => {
		MenuModule.setRoot(state[0].key); // 此处会触发 一级菜单 高亮
		const lastNode = state[state.length - 1]; // 获取最后一个节点，即当前页面节点
		let bcList = [];
		if (state.length === 1) {
			bcList = state;
		} else if (lastNode.type === 4) {
			// 最后一个子节点type===4 展示：当前级别
			bcList.push(lastNode);
		} else {
			//最后一个子节点type===2 展示：父级+子级（非第一条数据）
			bcList = state.slice(-2); // 倒数第二个元素开始截取
			if (lastNode.key === bcList[0].children[0].key) {
				// 子级（非第一条数据）
				bcList.pop(); // 删除最后一条
			}
		}
		MenuModule.setLeaf(bcList[0].key); // 此处会触发 手风琴菜单 高亮
		return bcList;
		// return state.ROLES;
	},
};

// mutations 私有化方法，只能被 actions 调用， 可以直接变更状态
const mutations = {
	CLEAR_BREADCRUMB: state => {
		state.BREADCRUMB = [];
	},
	SET_BREADCRUMB: (state, data) => {
		let breadCrumbList = [];
		let routesMatched = data.to.matched.slice(1);
		if (routesMatched.length) {
			breadCrumbList = routesMatched.map(item => {
				return {
					name: item.meta.title,
					path: item.path,
					type: item.meta.type,
					parent: item.meta.parent,
				};
			});
			switch (routesMatched.length) {
				case 1:
					state.BREADCRUMB = breadCrumbList;
					break;

				default:
					if (breadCrumbList[breadCrumbList.length - 1].parent) {
						// 若父子路由同级，则手动添加当前路由节点的parent信息
						let bcParent = breadCrumbList[breadCrumbList.length - 1].parent;
						breadCrumbList.splice(-1, 0, {
							name: bcParent.meta.title,
							path: bcParent.path,
							type: bcParent.meta.type,
						});
					}
					if (breadCrumbList[breadCrumbList.length - 1].type === 4) {
						state.BREADCRUMB = breadCrumbList.slice(-1);
					} else if (breadCrumbList[breadCrumbList.length - 1].type === 2) {
						if (
							breadCrumbList[breadCrumbList.length - 2].type === 2 ||
							breadCrumbList[breadCrumbList.length - 2].type === 4
						) {
							state.BREADCRUMB = breadCrumbList.slice(-2); // 当前路由节点的parent的type为2或4，则可点击跳转
						} else {
							state.BREADCRUMB = breadCrumbList.slice(-1);
						}
					}
					break;
			}
		}
	},
};

// actions 暴露到外部的方法 不直接变更状态
const actions = {
	clearBreadCrumb: ({ commit }, data) => {
		commit('CLEAR_BREADCRUMB', data);
	},
	setBreadCrumb: ({ commit }, data) => {
		commit('SET_BREADCRUMB', data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
