<template>
	<el-row class="com-image">
		<el-image :src="src" :preview-src-list="[src]" :fit="fit"></el-image>
		<el-button
			type="text"
			class="com-image__delete"
			icon="el-icon-close"
			@click="onDelete"
		></el-button>
	</el-row>
</template>
<script>
export default {
	name: 'ComImage',
	props: {
		src: {
			type: String,
		},
		fit: {
			type: String,
			default: 'contain',
		},
	},
	data() {
		return {};
	},
	methods: {
		onDelete() {
			this.$emit('delete');
		},
	},
};
</script>
<style scoped lang="scss">
.com-image {
	background-color: #fbfdff;
	border: 1px dashed #c0ccda;
	border-radius: 6px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 148px;
	height: 148px;
	cursor: pointer;
	line-height: 146px;
	vertical-align: top;
	position: relative;
	display: inline-block;
	.el-image {
		width: 100%;
		height: 100%;
	}

	&__delete {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(50%, -50%);
		width: 16px !important;
		height: 16px !important;
		padding: 0;
		border-radius: 50%;
		background-color: rgba($color: #000000, $alpha: 0.4);
		cursor: pointer;
		color: #fff;
	}
}
</style>
