<template>
	<div class="empty-page">
		<div class="default">
			<img
				class="empty-img"
				:src="img[empty_type] || other"
				:alt="empty_type"
			/>
		</div>
		<div class="empty-data" v-if="!$slots.default">{{ message }}</div>
		<slot v-else></slot>
	</div>
</template>

<script>
export default {
	name: 'LayoutEmpty',
	props: {
		/**
		 * 缺省文案
		 * 默认为‘暂无数据’
		 */
		message: {
			type: String,
			default: '暂无数据',
		},
		/**
		 * 缺省类别
		 * list: 列表缺省
		 * tag: 用户标签缺省
		 * draw: 用户画像缺省
		 * nowifi: 网络不佳
		 * 404: 404
		 */
		empty_type: {
			type: String,
			default: 'list',
		},
	},
	data() {
		return {
			other: require('@/assets/default/default_other.png'),
			img: {
				list: require('@/assets/default/default_noList.png'),
				tag: require('@/assets/default/default_noLabel.png'),
				draw: require('@/assets/default/default_noClient.png'),
				collect: require('@/assets/default/default_noClient.png'),
				nowifi: require('@/assets/default/default_netWorkError.png'),
				404: require('@/assets/default/default_404.png'),
			},
		};
	},
	mounted() {},
};
</script>

<style lang="scss">
.empty-page {
	width: 100%;
	text-align: center;
	padding: 100px 0;
}

.empty-data {
	color: #292a32;
	font-size: 14px;
}

.empty-img {
	width: 240px;
	height: 190px;
}
</style>
